body.is--works {
  
  @include pc {
    .breadcrumbs {
      margin-top: 33px;
    }
    .rwd002-container {
      margin-top: 18px;
    }
    footer {
      margin-top: 130px;
    }
  }

  &.is--list {
    .rwd002 {
      &-main, &-taglist {
        max-width: 1060px;
        margin-inline: auto;
        padding-inline: 50px;
        box-sizing: border-box;
      }
      &-taglist {
        margin-top: 25px;
        &__cats {
          display: flex;
          flex-wrap: wrap;
          gap: 20px 50px;
          max-width: 630px;
          margin-inline: auto;
          a {
            font-weight: 700;
            line-height: 1;
            letter-spacing: .05em;
            &.current {
              display: flex;
              align-items: center;
              column-gap: 5px;
              &::before {
                content: '';
                display: block;
                position: relative;
                width: 10px;
                aspect-ratio: 1 / 1;
                overflow: hidden;
                border-radius: 50%;
                background-color: $theme-pink;
              }
            }
            &:hover {
              color: $theme-pink;
            }
          }
        }
        &__tags {
          margin-top: 50px;
          padding: 40px 40px;
          background-color: #F5F5F5;
          box-sizing: border-box;
          border-radius: 5px;
        }
        &__list {
          display: flex;
          flex-wrap: wrap;
          gap: 15px 10px;
          max-width: 700px;
          margin: auto;
          a {
            font-size: 12px;
            line-height: 1;
            letter-spacing: .05em;
            color: #808080;
            &:hover {
              opacity: .7;
            }
          }
        }
        &__btnwrap {
          margin-top: 20px;
        }
        &__tglBtn {
          font-size: 13px;
          font-weight: 700;
          color: $font-color;
          display: block;
          padding: 0;
          border: none;
          margin: auto;
          display: flex;
          align-items: center;
          column-gap: 10px;
          line-height: 1;
          background: none;
          .plus, .minus {
            position: relative;
            display: block;
            width: 10px;
            height: 10px;
            &::before, &::after {
              content: '';
              display: block;
              position: absolute;
              width: 100%;
              height: 1px;
              background-color: $font-color;
              inset: 0;
              margin: auto;
            }
          }
          .plus::after {
            transform: rotate(90deg);
          }
        }

        @include pc {
          &__list a {
            display: block !important;
          }
          &__btnwrap {
            display: none;
          }
        }
      }
    }

    .works_list {
      margin-top: 75px;
      @include pc {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 65px 38px;
      }
      a {
        figure {
          border-radius: 15px;
          overflow: hidden;
          aspect-ratio: 3 / 2;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: $transition-base;
          }
        }
        .desc {
          margin-top: 20px;
        }
        .cat {
          display: flex;
          align-items: center;
          column-gap: 7px;
          font-size: 13px;
          line-height: 1;
          letter-spacing: .05em;
          &::before {
            content: '';
            display: block;
            position: relative;
            width: 8px;
            aspect-ratio: 1 / 1;
            overflow: hidden;
            border-radius: 50%;
            background-color: $theme-pink;
          }
        }
        .ttl {
          margin-top: 10px;
          font-size: 17px;
          font-weight: 700;
          line-height: 1.3;
          letter-spacing: .15em;
        }
        .tag_list {
          margin-top: 15px;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          li {
            font-size: 11px;
            line-height: 1;
            letter-spacing: .05em;
            color: #808080;
          }
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
          .ttl {
            color: $theme-pink;
          }
        }
      }
    }

    .rwd002-pagination {
      &__pageNav {
        border-bottom-width: 0;
        border-color: #C0C0C0;
        align-items: center;
        column-gap: 30px;
        padding-top: 27px;
      }
      &__pages {
        column-gap: 15px;
        span, a {
          display: grid;
          place-content: center;
          width: 30px;
          aspect-ratio: 1 / 1;
          overflow: hidden;
          height: unset;
          border-radius: 50%;
          @include Viga;
          letter-spacing: .2em;
          font-size: 18px;
          line-height: 1;
        }
        span {
          background-color: $theme-pink;
          color: #fff;
        }
        a {
          color: #808080;
          background: none;
          &:hover {
            color: $theme-pink;
          }
        }
      }
      .is-prev, .is-next {
        background: none;
        align-items: center;
        text-indent: 0;
        transition: all .2s ease-in-out;
        width: 10px;
        height: 10px;
        position: relative;
        margin: 0;
        transition: $transition-base;
        &::before {
          content: "";
          display: block;
          background: none;
          height: 100%;
          width: 100%;
          border-right: 2px solid $theme-pink;
          border-bottom: 2px solid $theme-pink;
        }
        &::after {
          display: none;
        }
        &.disable {
          opacity: 0;
        }
      }
      .is-prev {
        &::before {
          transform: rotate(135deg);
        }
        &:hover {
          transform: translateX(-5px);
        }
      }
      .is-next {
        &::before {
          transform: rotate(-45deg);
        }
        &:hover {
          transform: translateX(5px);
        }
      }
    }

    @include sp {
      .rwd002 {
        &-main {
          padding-inline: 27px;
        }
        &-taglist {
          margin-top: 36px;
          padding-inline: 17px;
          &__cats {
            gap: 17px 0;
            margin-inline: 10px;
            a {
              width: 32%;
              text-align: center;
              &.current {
                justify-content: center;
              }
            }
          }
          &__tags {
            margin-top: 25px;
            padding: 28px 16px 20px;
          }
          &__list {
            row-gap: 13px;
          }
        }
      }
  
      .works_list {
        margin-top: 43px;
        > li + li {
          margin-top: 50px;
        }
        a {
          .desc {
            margin-top: 14px;
          }
          .cat {
            column-gap: 5px;
          }
          .ttl {
            font-size: 16px;
          }
          .tag_list {
            gap: 10px;
          }
        }
      }
  
      .rwd002-pagination {
        &__pageNav {
          margin-inline: 27px;
        }
      }
    }
  }

  &.is--detail {
    .rwd002-detail {
      margin-top: 0;
      &__category {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: .05em;
        max-width: unset;
        min-width: unset;
        padding: 0;
        text-align: left;
        text-indent: 0;
        margin-bottom: 0;
        border: none;
        color: $font-color;
        transition: $transition-base;
        &::before {
          content: '';
          display: inline-block;
          position: relative;
          width: 8px;
          aspect-ratio: 1 / 1;
          overflow: hidden;
          border-radius: 50%;
          background-color: $theme-pink;
          margin-right: 5px;
          bottom: 1px;
        }
        &:hover {
          color: $font-color;
          background: none;
          opacity: .7;
        }
      }
      &__title {
        padding-bottom: 0;
        margin-block: 12px 0;
        border-bottom: none;
        font-size: 25px;
        letter-spacing: .15em;
        line-height: 1.2;
      }

      .tag_list {
        margin-top: 17px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px 12px;
        li {
          line-height: 1;
        }
        a {
          font-size: 11px;
          font-weight: 500;
          line-height: 1;
          letter-spacing: .05em;
          color: #808080;
          &:hover {
            opacity: .7;
          }
        }
      }

      &__entry {
        margin-top: 26px;
      }

      &__image {
        border-radius: 15px;
        overflow: hidden;
      }

      @include sp {
        &__title {
          margin-top: 10px;
          font-size: 16px;
        }
        .tag_list {
          margin-top: 17px;
          gap: 10px 12px;
        }
        &__entry {
          margin-top: 26px;
        }
      }
    }
  }
}