$gnav-height-xs: 48px;

#allbox {
  // ナビバーが画面固定されたときのクリアランス
  // @include media-down(md) {
  //   padding-top: 48px;
  // }
}

.rwd002-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  z-index: 100;
  position: relative;

  @include media-up(lg) {
    padding-left: min(map-get($spacers, 5), 5vw);
  }

  @include media-down(lg) {
    height: 48px;
    background-color: $white;
    overflow: hidden;
    padding-left: 16px;
  }

  &__logo {
    flex-grow: 1;
    margin: 0;
    max-height: 100%;
    &-link {
      max-width: 134px;
      display: block;
      &:hover,
      &:focus {
        text-decoration: none;
        opacity: .7;
      }
      @include sp {
        width: 79px;
      }
    }
    img {
      width: 100%;
    }
  }

}

.rwd002-header__drawerBtn {
  @extend .btn;
  width: 48px;
  height: 48px;
  position: relative;
  background: $theme-grd;
  border-radius: 0;
  z-index: 101;

  &:focus {
    box-shadow: none;
  }

  @include media-up(lg) {
    display: none;
  }

  &__bars {
    stroke: #fff;
  }

  &__bar01,
  &__bar02,
  &__bar03 {
    transform-origin: center;
    transition: $transition-base;
  }

  .is-active &__bar01 {
    transform: rotate(135deg) translateY(50%);
  }

  .is-active &__bar02 {
    transform: rotate(45deg);
    opacity: 0;
  }

  .is-active &__bar03 {
    transform: rotate(225deg) translateY(-50%);
  }
}
