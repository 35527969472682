.breadcrumbs {
  margin-top: 13px;
  .container {
    max-width: 1300px;
  }
  ul {
    display: flex;
    justify-content: flex-end;
  }
  li {
    font-size: 10px;
    letter-spacing: .18em;
    color: #808080;
    line-height: 1;
    + li {
      &::before {
        content: '-';
      }
    }
    a {
      font-size: 10px;
      letter-spacing: .18em;
      color: #808080;
      line-height: 1;
      &:hover {
        color: $theme-pink;
      }
    }
  }

  @include sp {
    display: none;
  }
}
