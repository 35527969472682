body.is--company.is--article-2 {
  .rwd002 {
    &-container {
      margin: 0;
      padding: 0;
    }
    &-detail {
      margin: 0;
      padding: 0;
      &__entry {
        max-width: unset;
      }
    }
  }

  .anchor_wrap {
    margin-top: 32px;
  }
  @include pc {
    .anchor_list {
      max-width: 670px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        &:nth-of-type(-n + 2){
          width: 310px;
        }
        &:nth-of-type(n + 3){
          width: 134px;
        }
      }
    }
  }

  .slash_ttl {
    justify-content: center;
  }

  .block-photo {
    margin-top: 94px;
    .unit {
      + .unit {
        margin-top: 150px;
      }
    }

    .img_list {
      margin-top: 42px;
      picture {
        display: block;
        border-radius: 15px;
        overflow: hidden;
      }
      p {
        line-height: 1.2;
        margin-top: 17px;
        font-weight: 700;
      }
      @include pc {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 40px;
        li {
          width: calc(50% - 20px);
          &:nth-of-type(n + 5){
            width: 32%;
            p {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }

  .block-table {
    margin-top: 205px;
    .unit {
      + .unit {
        margin-top: 117px;
      }
      .slash_ttl {
        margin-bottom: 40px;
      }
    }

    .table_wrap {
      + .table_wrap {
        margin-top: 52px;
      }
    }

    table {
      width: 100%;
      th, td {
        border: 1px solid #DBDBDB;
        box-sizing: border-box;
      }
      th {
        background-color: #E7E7E7;
        line-height: 1;
        font-weight: 700;
        text-align: center;
        padding: 12px 10px;
      }
      td {
        font-size: 13px;
        line-height: 1.5;
        padding: 10px 28px;
        @include pc {
          &.ttl {
            width: 233px;
            text-align: center;
            padding-inline: 10px;
          }
          &:not(:last-of-type){
            white-space: nowrap;
          }
        }
      }
    }
  }

  @include sp {
    .anchor_wrap {
      margin-top: 32px;
    }

    .anchor_list {
      li {
        &:first-of-type {
          grid-column: 1 / 3;
        }
      }
    }
  
    .slash_ttl {
      align-items: flex-start;
      text-align: center;
      &::before {
        top: 2px;
      }
    }
  
    .block-photo {
      margin-top: 58px;
      .unit {
        + .unit {
          margin-top: 60px;
        }
      }
  
      .img_list {
        margin-top: 28px;
        li + li {
          margin-top: 30px;
        }
        p {
          margin-top: 13px;
        }
        img{
          width: 100%;
        }
      }
    }
  
    .block-table {
      margin-top: 58px;
      .unit {
        + .unit {
          margin-top: 60px;
        }
        .slash_ttl {
          margin-bottom: 17px;
        }
      }
  
      .table_wrap {
        + .table_wrap {
          margin-top: 52px;
        }
      }

      .scroll_text {
        font-size: 12px;
        line-height: 1;
        letter-spacing: .13em;
        text-align: right;
        padding-right: 38px;
        position: relative;
        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          height: 1px;
          background-color: $font-color;
          right: 0;
          bottom: 2px;
        }
        &::before {
          width: 34px;
        }
        &::after {
          width: 8px;
          transform: rotate(45deg);
          transform-origin: right bottom;
        }
      }
      .scroll_wrap {
        margin-top: 15px;
        margin-right: -27px;
        overflow: auto;
      }
  
      table {
        width: auto;
        th, td {
          white-space: nowrap;
        }
        th {
          padding: 12px 10px;
        }
        td {
          font-size: 13px;
          padding: 10px 28px;
        }
      }
    }
  
    .bros_link_wrap.bottom {
  
    }
  }
}