body.is--business {
  .rwd002 {
    &-container {
      margin: 0;
      padding: 0;
    }
    &-detail {
      margin: 0;
      padding: 0;
      &__entry {
        max-width: unset;
      }
    }
  }

  .bros_link {
    grid-template-columns: repeat(3, 1fr);
    max-width: 874px;
  }

  .business_bros_link {
    a {
      display: grid;
      position: relative;
      height: 180px;
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid $theme-pink;
      box-sizing: border-box;
      &::before {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        background: $theme-grd;
        opacity: 0;
        transition: $transition-base;
        z-index: -1;
      }
      figure {
        display: grid;
        place-content: center;
        width: 53px;
        height: 44px;
        .business { width: 50px;}
        .cable { width: 53px;}
        .invent { width: 43px;}
      }
      p {
        margin-top: 18px;
        strong, span {
          display: block;
        }
        strong {
          font-size: 16px;
          line-height: 1.1;
          letter-spacing: .15em;
        }
        span {
          @include Viga;
          font-size: 10px;
          line-height: 1;
          letter-spacing: .12em;
          margin-top: 9px;
          color: $theme-pink;
        }
      }
      .arrow {
        width: 12px;
        position: absolute;
        right: 15px;
        bottom: 20px;
      }
      &:hover, &.current {
        &::before {
          opacity: .4;
        }
        .arrow {
          transform: translateX(5px);
        }
      }

    }
    @include pc {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 20px;
      a {
        place-content: center;
        place-items: center;
        p {
          text-align: center;
        }
      }
    }
  }

  @include sp {
    .bros_link {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 8px;
      a {
        height: 54px;
        border-radius: 5px;
        text-align: center;
        padding-inline: 5px;
        justify-content: center;
        &::before, &::after {
          border-radius: 5px;
        }
        span {
          font-size: 13px;
          letter-spacing: .08em;
          line-height: 1.2;
        }
        .arrow {
          right: 7px;
          bottom: 5px;
          top: auto;
          width: 12px;
        }
      }

      &_wrap.bottom {
        margin-top: 74px;
        .container {
          padding-inline: 27px;
        }
      }
    }
  
    .business_bros_link {
      li + li {
        margin-top: 10px;
        @media(min-width:765px){
          margin-top: 0;
        }
      }
      a {
        height: 90px;
        border-radius: 10px;
        padding-inline: 28px;
        display: grid;
        grid-template-columns: 73px 1fr;
        align-items: center;
        figure {
          width: 48px;
          height: 39px;
          .business { width: 45px;}
          .cable { width: 48px;}
          .invent { width: 39px;}
        }
        p {
          margin-top: 0;
          strong {
            font-size: 15px;
          }
          span {
            margin-top: 8px;
          }
          
        }
        .arrow {
          width: 12px;
          position: absolute;
          right: 15px;
          bottom: 10px;
        }
        @media(min-width:765px){
          display: flex;
          flex-direction: column;
          height: 180px;
          justify-content: center;
          p{
            margin-top: 18px;
          }
        }
      }
      @media(min-width:765px){
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
        a {
          p {
            text-align: center;

          }
        }
      }
    }   
  }

  .point_card {
    + .point_card {
      margin-top: 10px;
    }

    picture {
      display: block;
      border-radius: 15px;
      overflow: hidden;
    }
    h4 {
      margin-top: 14px;
      font-size: 25px;
      line-height: 1.7;
      letter-spacing: .08em;
    }
    .text {
      margin-top: 10px;
    }
    
    @include pc {
      display: grid;
      grid-template-columns: 325px 1fr;
      column-gap: 53px;
      align-items: center;
      background-color: #F5F5F5;
      border-radius: 15px;
      box-sizing: border-box;
      padding: 45px;
    }
  }
  @include sp {
    .point_list:has(.point_card){
      background-color: #F5F5F5;
      border-radius: 15px;
      box-sizing: border-box;
      padding: 45px 20px 60px;
      margin-inline: -12px;
    }
    .point_card {
  
      + .point_card {
        margin-top: 37px;
      }
      .desc {
        margin: 20px 10px 0;
      }
      h4 {
        margin-top: 17px;
        font-size: 20px;
        line-height: 1.6;
      }
      .text {
        margin-top: 12px;
      }
    }
  }

  .content_card {
    picture {
      display: block;
    }
    p {
      margin-top: 20px;
    }

    @include pc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-block: 76px;
      position: relative;
      picture {
        width: 44%;
        border-radius: 15px;
        overflow: hidden;
      }
      .desc {
        width: 50%;
      }
      + .content_card {
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: calc(100vw - 140px);
          height: 1px;
          background-color: #C0C0C0;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
        }
      }
      &:nth-of-type(2n){
        flex-direction: row-reverse;
      }
    }

    @include sp {
      + .content_card {
        margin-top: 72px;
      }
      picture {
        margin-inline: -27px;
      }
      .desc {
        margin-top: 31px;
      }
      p {
        margin-top: 12px;
      }
    }
  }

  &.is--article-1 {
    .block-about {
      padding-block: 85px 120px;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: calc(100% + 200px);
        background-color: #F5F5F5;
        left: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 0 0 100px 100px;
      }
      p {
        font-weight: 700;
        line-height: 2.6;
        letter-spacing: .12em;
        margin-top: 35px;
        @include pc {
          text-align: center;
        }
      }
      picture {
        position: absolute;
      }
      @include pc {
        .slash1 {
          width: 387px;
          right: calc(50% + 295px);
          top: 251px;
        }
        .slash2 {
          width: 355px;
          left: calc(50% + 385px);
          top: 74px;
        }
        .img1 {
          right: calc(50% + 208px);
          width: clamp(290px,28vw,366px);
          top: 139px;
        }
        .img2 {
          left: calc(50% + 216px);
          width: clamp(290px, 28vw, 390px);
          top: 1px;
        }
      }
    }
    
    .block-business {
      padding-top: 125px;
      .content_wrap {
        &:first-of-type {
          margin-top: 70px;
        }
        + .content_wrap {
          margin-top: 150px;
        }
        @include pc {
          .unit {
            display: flex;
            align-items: center;
            picture {
              display: block;
              width: 50%;
              height: 370px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .desc {
              max-width: 530px;
              padding-inline: 50px;
            }
          }
          &:nth-of-type(2n + 1) .unit {
            flex-direction: row-reverse;
            picture {
              border-radius: 15px 0 0 15px;
            }
            .desc {
              margin-inline: auto 0;
            }
          }
          &:nth-of-type(2n) .unit {
            picture {
              border-radius: 0 15px 15px 0;
            }
          }
        }
      }

      .unit {
        picture {
          display: block;
        }
        .desc {
          
        }
        .place {
          display: flex;
          align-items: center;
          font-weight: 700;
          letter-spacing: .2em;
          line-height: 1;
          column-gap: 2px;
          &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $theme-pink;
          }
        }
        h4 {
          font-size: 30px;
          line-height: 1.4;
          letter-spacing: .12em;
          margin-top: 7px;
        }
        .text {
          margin-top: 15px;
        }
        .btn_wrap {
          margin-top: 39px;
        }
      }

      .list_wrap {
        margin-top: 83px;
      }

      .link_list {
        > li {
          + li {
            margin-top: 40px;
          }
        }
        a {
          background-color: #F5F5F5;
          border-radius: 15px;
          overflow: hidden;
          position: relative;
          &::before {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            background-color: #000;
            opacity: 0;
            transition: $transition-base;
            mix-blend-mode: multiply;
            border-radius: 15px;
            overflow: hidden;
          }
          @include pc {
            display: grid;
            grid-template-columns: 377px 1fr;
            height: 204px;
            align-items: center;
          }
          picture {
            width: 100%;
            height: 100%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: $transition-base;
            }
          }
          .desc {
            padding: 20px 40px;
            box-sizing: border-box;
          }
          h5 {
            font-size: 24px;
            line-height: 1;
            letter-spacing: .12em;
          }
          ul {
            margin-top: 22px;
            li {
              font-size: 14px;
              font-weight: 700;
              line-height: 1.4;
              display: flex;
              column-gap: 2px;
              &::before {
                content: '';
                display: block;
                position: relative;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $font-color;
                top: 4px;
              }
              + li {
                margin-top: 7px;
              }
            }
          }
          .arrow {
            width: 16px;
            position: absolute;
            right: 27px;
            bottom: 23px;
          }
          &:hover {
            &::before {
              opacity: .15;
            }
            .arrow {
              transform: translateX(5px);
            }
          }
        }
      }
    }

    @include sp {
      .block-about {
        padding-block: 54px 80vw;
        &::before {
          height: calc(100% + 200px);
          border-radius: 0 0 25px 25px;
        }
        p {
          line-height: 1.9;
          letter-spacing: .08em;
          margin-top: 28px;
        }
        .slash1 {
          width: 269px;
          left: -100px;
          bottom: 31vw;
        }
        .slash2 {
          width: 116px;
          right: -29px;
          top: -3px;
        }
        .img1 {
          display: block;
          width: calc(100% - 27px);
          right: 0;
          bottom: -32px;
          @media(min-width: 650px){
            img{
              width: 90%;
              display: block;
              margin: 0 auto;
            }
          }
        }
      }
      
      .block-business {
        padding-top: 79px;
        .content_wrap {
          &:first-of-type {
            margin-top: 29px;
          }
          + .content_wrap {
            margin-top: 70px;
            img{
              width: 100%;
            }
          }
        }
  
        .unit {
          picture {
            display: block;
            img{
              width: 100%;
            }
          }
          .desc {
            margin: 40px 27px 0;
          }
          .place {
            font-size: 13px;
            column-gap: 2px;
            &::before {
              width: 8px;
              height: 8px;
            }
          }
          h4 {
            font-size: 20px;
            margin-top: 7px;
          }
          .text {
            margin-top: 11px;
          }
          .btn_wrap {
            margin-top: 22px;
          }
        }
  
        .list_wrap {
          margin-top: 52px;
          .container {
            padding-inline: 18px;
          }
        }
  
        .link_list {
          > li {
            + li {
              margin-top: 20px;
            }
          }
          a {
            border-radius: 15px;
            padding: 27px 20px 31px;
            box-sizing: border-box;
            display: block;
            picture {
              border-radius: 10px;
              overflow: hidden;
              display: block;
            }
            .desc {
              padding: 0;
              margin-top: 23px;
            }
            h5 {
              font-size: 20px;
            }
            ul {
              margin-top: 17px;
              li {
                font-size: 13px;
                &::before {
                  width: 8px;
                  height: 8px;
                  top: 3px;
                }
                + li {
                  margin-top: 7px;
                }
              }
            }
            .arrow {
              right: 16px;
              bottom: 15px;
            }
          }
        }
      }      
    }
  }
}