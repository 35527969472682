body {
  color: $font-color;

  #allbox {
    overflow: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    color: $font-color;
    font-weight: 700;
  }
  p, a, span, li, dt, dd, th, td, div {
    font-size: 15px;
    font-weight: 500;
    color: $font-color;
    line-height: 2;
    letter-spacing: .08em;
    @include sp {
      line-height: 1.9;
    }
  }
  h1, h2, h3, h4, h5, h6, p, a, span, li, dl, dt, dd, th, td, div, figure {
    margin: 0;
    line-break: strict;
  }
  a {
    transition: .5s;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  img {
    max-width: 100%;
  }
}

.pc {
  @include sp {
    display: none !important;
  }
}
.sp {
  @include pc {
    display: none !important;
  }
}

.rwd002-block-freearea {
  margin: 0;
  padding: 0;
  &__inner {
    max-width: unset;
  }
}

.container {
  max-width: 1060px;
  margin-inline: auto;
  padding-inline: 50px;
  @include sp {
    padding-inline: 27px;
  }
}

@keyframes scroll_line {
  100% {
    transform: scaleX(0);
  }
}

.img_carousel {
  overflow: hidden;
  ul {
    animation: imgCarousel 60s linear infinite;
    column-gap: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 5630px;
  }
  @include sp {
    ul {
      column-gap: 6px;
      width: 2700px;
    }
  }
}
@keyframes imgCarousel {
  100% {
    transform: translateX(-2820px);
  }
}
@include sp {
  @keyframes imgCarousel {
    100% {
      transform: translateX(-1353px);
    }
  }
}

svg.arrow {
  fill: none;
  stroke-miterlimit: 10;
  aspect-ratio: 7 / 4;
  overflow: hidden;
  transition: $transition-base;
  &.arrow_wh {
    stroke: #fff;
  }
  &.arrow_pk {
    stroke: $theme-pink;
  }
  &.arrow_bk {
    stroke: $font-color;
  }
}

.bros_link {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  max-width: 574px;
  column-gap: 25px;
  margin-inline: auto;

  a {
    display: flex;
    align-items: center;
    position: relative;
    height: 45px;
    z-index: 1;
    border-radius: 23px;
    overflow: hidden;
    padding-inline: 20px;
    box-sizing: border-box;

    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      border-radius: 23px;
    }
    &::before {
      background: $theme-grd;
      z-index: -2;
    }
    &::after {
      background-color: #fff;
      border: 1px solid $theme-pink;
      box-sizing: border-box;
      z-index: -1;
      transition: $transition-base;
    }

    span {
      font-size: 13px;
      font-weight: 700;
      letter-spacing: .13em;
      transition: $transition-base;
    }
    .arrow {
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 14px;
    }
    &:hover, &.current {
      &::after {
        opacity: 0;
      }
      span {
        color: #fff;
      }
      .arrow {
        transform: translateX(5px);
        stroke: #fff;
      }
    }
  }

  @include pc {
    &_large {
      max-width: unset;
      a {
        justify-content: center;
        height: 90px;
        border-radius: 45px;
        &::before, &::after {
          border-radius: 45px;
        }
        span {
          font-size: 16px;
        }
        .arrow {
          right: 36px;
        }
      }
    }

    &_wrap {
      &.top {
        margin-top: -23px;
      }
      &.bottom {
        margin-top: 100px;
      }
    }
  }

  @include sp {
    column-gap: 12px;
    a {
      height: 37px;
      border-radius: 19px;
      &::before, &::after {
        border-radius: 19px;
      }
      span {
        font-size: 13px;
        letter-spacing: .13em;
      }
      .arrow {
        right: 17px;
        width: 10px;
      }
    }

    &_large {
      a {
        height: 47px;
        border-radius: 24px;
        column-gap: 10px;
        &::before, &::after {
          border-radius: 24px;
        }
        span {
          letter-spacing: .15em;
        }
      }
    }

    &_wrap {
      &.top {
        margin-top: 24px;
      }
      &.bottom {
        margin-top: 50px;
        .container {
          padding-inline: 17px;
        }
      }
    }
  }
}

.anchor_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px 35px;
  margin-inline: auto;
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 26px;
    border-bottom: 1px solid $theme-pink;
    span {
      font-size: 13px;
      letter-spacing: .15em;
      transition: $transition-base;
    }
    .arrow_wrap {
      display: grid;
      place-content: center;
      width: 15px;
      aspect-ratio: 1 / 1;
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid $theme-pink;
      transition: $transition-base;
      &::before {
        content: '';
        display: block;
        position: relative;
        width: 4px;
        height: 4px;
        border-right: 1px solid $theme-pink;
        border-bottom: 1px solid $theme-pink;
        transform: rotate(45deg);
        bottom: 1px;
        transition: $transition-base;
      }
    }
    &:hover {
      span {
        color: $theme-pink;
      }
      .arrow_wrap {
        background-color: $theme-pink;
        &::before {
          border-color: #fff;
        }
      }
    }
  }

  @include sp {
    grid-template-columns: repeat(2, 1fr);
    gap: 19px 25px;
    a {
      height: 22px;
      span {
        font-size: 12px;
        letter-spacing: .05em;
      }
    }
  }
}

.base_table {
  display: block;
  tbody {
    display: block;
  }
  tr {
    padding: 18px 0 18px 14px;
    box-sizing: border-box;
    position: relative;
    + tr {
      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        left: 0;
        top: 0;
      }
      &::before {
        width: 100%;
        background-color: #D5D5D5;
      }
      &::after {
        width: 123px;
        background-color: #8E8E8E;
      }
    }
    @include pc {
      display: grid;
      grid-template-columns: 150px 1fr;
      column-gap: 10px;
      align-items: baseline;
    }
  }
  th, td {
    padding: 0;
  }
  th {
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: .09em;
  }
  th, td {
    display: block;
  }
  td, p, ul, li {
    line-height: 1.5;
    letter-spacing: .09em;
    margin: 0;
  }
  ul {
    list-style: none;
    li {
      display: flex;
      column-gap: 6px;
      margin: 0;
      &::before {
        content: '・';
      }
    }
  }

  @include sp {
    tr {
      padding: 20px 0 15px;
      display: block;
    }
    td {
      margin-top: 6px;
    }
  }  
}

.slash_grd {
  width: 139%;
  aspect-ratio: 1.25 / 1;
  overflow: hidden;
  clip-path: polygon(75% 0, 100% 0, 25% 100%, 0 100%);
  position: absolute;
  z-index: -1;
  &::before {
    content: "";
    position: absolute;
    top: 200%;
    left: -179%;
    right: 0;
    bottom: 0;
    display: block;
    width: 400%;
    height: 400%;
    margin: auto;
    background: linear-gradient(30deg, #FF0025, #FF5180 50%, #FF0025);
    background-size: 100% 100%;
    animation: gradient 10s ease infinite;
    z-index: -1;
  }

  @include sp {
    width: 288%;
  }

  @keyframes gradient {
    0% {
      top: 200%;
    }
    50% {
      top: -200%;
    }
    100% {
      top: 200%;
    }
    
  }
}