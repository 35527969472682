body.is--recruit {
  .rwd002 {
    &-container {
      margin: 0;
      padding: 0;
    }
    &-detail {
      margin: 0;
      padding: 0;
      &__entry {
        max-width: unset;
      }
    }
  }

  .block-entry {
    background: $theme-grd;
    padding-block: 76px 115px;
    h3 {
      text-align: center;
      strong,
      span {
        display: block;
        line-height: 1;
        color: #fff;
      }
      strong {
        @include Viga;
        font-size: 51px;
        line-height: 1;
        letter-spacing: 0.12em;
      }
      span {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.2em;
        margin-top: 13px;
      }
    }
    ul {
      margin: 37px auto 0;
      @include pc {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 50px;
        max-width: 800px;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        height: 83px;
        border-radius: 42px;
        background-color: #fff;
        position: relative;
      }
      .icon {
        &_guide {
          width: 17px;
        }
        &_entry {
          width: 26px;
        }
      }
      span {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.12em;
        transition: $transition-base;
      }
      .arrow {
        width: 14px;
        position: absolute;
        right: 33px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      a:hover {
        span {
          color: $theme-pink;
        }
        .arrow {
          transform: translateX(5px);
        }
      }
    }

    @include sp {
      margin-top: 60px;
      padding-block: 40px 45px;
      h3 {
        text-align: center;
        strong {
          font-size: 38px;
        }
        span {
          font-size: 13px;
          margin-top: 10px;
        }
      }
      ul {
        margin-top: 29px;
        li + li {
          margin-top: 13px;
        }
        a {
          column-gap: 7px;
          height: 62px;
          border-radius: 31px;
        }
        .icon {
          &_guide {
            width: 15px;
          }
          &_entry {
            width: 22px;
          }
        }
        span {
          font-size: 15px;
        }
        .arrow {
          width: 14px;
          right: 19px;
        }
      }
    }
  }

  &.is--article-1 {
    .slash_ttl {
      justify-content: center;
    }

    .block-message {
      margin-top: 138px;
      position: relative;
      .slash_grd {
        left: -53%;
        top: -255px;
        opacity: 0.4;
      }
      .message_wrap {
        h3 {
          position: relative;
          max-inline-size: max-content;
          margin-inline: auto;
          text-align: center;
          picture {
            display: block;
            position: absolute;
            left: -125px;
            top: -65px;
            width: 308px;
          }
          span {
            font-size: 29px;
            font-weight: 700;
            letter-spacing: 0.11em;
            line-height: 1.7;
          }
        }

        p {
          margin-top: 48px;
          line-height: 2.2;
          font-weight: 700;
          @include pc {
            text-align: center;
          }
        }
      }

      .feature_wrap {
        margin-top: 120px;
        border-radius: 15px;
        border: 1px solid $theme-pink;
        position: relative;
        background: #fff url(/assets/img/recruit/top/feature_bg@2x.png) no-repeat left / cover;
        padding-inline: min(8vw, 110px) 394px;
        box-sizing: border-box;
        picture {
          display: block;
          border-radius: 15px;
          overflow: hidden;
        }
        .slash_ttl {
          column-gap: 15px;
          font-size: 21px;
          line-height: 1;
          &::before {
            width: 22px;
          }
        }
        p {
          margin-top: 30px;
        }

        @include pc {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 436px;
          picture {
            position: absolute;
            width: 463px;
            right: -100px;
            top: -30px;
          }
          .slash_ttl {
            justify-content: flex-start;
          }
        }
      }
    }

    .block-data {
      margin-top: 165px;
      padding-block: 137px 110px;
      background-color: #f5f5f5;
      border-radius: 100px;
      overflow: hidden;
      .container {
        padding-inline: 0;
        max-width: 960px;
      }
      .data_list {
        margin-top: 45px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 6px;
        > li {
          background-color: #fff;
          border-radius: 5px;
          box-sizing: border-box;
          min-height: 242px;
          padding: 30px 5px;
          h4 {
            text-align: center;
            font-size: 17px;
            line-height: 1;
            letter-spacing: 0.18em;
            padding-bottom: 11px;
            position: relative;
            &::before {
              content: "";
              display: block;
              position: absolute;
              width: 31px;
              height: 1px;
              background-color: $theme-pink;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
            }
          }
          .data {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-top: 25px;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 0.08em;
            line-height: 1;
            strong {
              line-height: 26px;
              font-size: 40px;
              letter-spacing: 0.02em;
              @include Viga;
              &.zero {
                font-size: 29px;
              }
            }
            span {
              font-size: 18px;
              font-weight: 700;
              letter-spacing: 0.18em;
              line-height: 1;
            }
          }
          .data + img {
            margin: 27px auto 0;
          }
          img {
            display: block;
          }
          .wide_wrap {
            display: grid;
            grid-template-columns: 140px 1fr;
            align-items: center;
            max-width: 340px;
            margin: 20px auto 0;
            table {
              display: block;
              max-width: 200px;
              tbody {
                display: block;
              }
              tr {
                display: flex;
                justify-content: space-between;
                position: relative;
                align-items: center;
                z-index: 1;
                &::before {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 1px;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  border-top: 1px dotted rgb(55, 55, 55, 0.5);
                  z-index: -1;
                }
                + tr {
                  margin-top: 8px;
                }
              }
              th,
              td {
                display: block;
                background-color: #fff;
                line-height: 1;
              }
              th {
                font-weight: 700;
                padding-right: 5px;
                &::before {
                  content: "■";
                }
              }
              td {
                font-size: 21px;
                font-weight: 700;
                padding-left: 10px;
                strong {
                  font-size: 25px;
                  line-height: 1;
                  letter-spacing: 0.2em;
                  @include Viga;
                }
              }
            }
          }
          .off_wrap {
            margin: 33px 26px 0;
            .rank_list {
              display: grid;
              grid-template-columns: repeat(3, 120px);
              justify-content: space-between;
              padding-bottom: 20px;
              border-bottom: 1px dotted rgb(55, 55, 55, 0.5);
              figure {
                display: grid;
                place-content: center;
                width: 62px;
                height: 58px;
                margin-inline: auto;
                .rank1 {
                  grid-template-columns: 55px;
                }
                .rank2 {
                  grid-template-columns: 62px;
                }
                .rank3 {
                  grid-template-columns: 55px;
                }
                img {
                  width: 100%;
                }
              }
              p {
                margin-top: 20px;
                text-align: center;
                font-size: 13px;
                font-weight: 700;
                line-height: 1.4;
              }
            }
            .text_list {
              display: grid;
              grid-template-columns: 124px 92px 140px;
              row-gap: 14px;
              padding-top: 14px;
              justify-content: center;
              li {
                font-size: 12px;
                font-weight: 700;
                line-height: 1;
                &::before {
                  content: "■";
                  margin-right: 2px;
                }
              }
            }
          }
        }
        @include pc {
          .pc01 {
            grid-column: 1 / 3;
            img {
              width: 86px;
            }
          }
          .pc02 img {
            width: 52px;
          }
          .pc03 img {
            width: 50px;
          }
          .pc04 img {
            width: 57px;
          }
          .pc05 img {
            width: 57px;
          }
          .pc06 img {
            width: 53px;
          }
          .pc07 img {
            width: 57px;
          }
          .pc08 img {
            width: 57px;
          }
          .pc09 img {
            width: 64px;
          }
          .pc10 {
            grid-column: 3 / 5;
            grid-row: 3 /4;
            img {
              width: 74px;
            }
          }
          .pc11 {
            grid-column: 1 / 3;
            grid-row: 4 / 5;
            padding-top: 48px;
          }
          .pc12 {
            grid-column: 3 / 5;
            grid-row: 4 / 5;
            padding-top: 48px;
            .wide_wrap {
              margin-top: 52px;
            }
            img {
              width: 92px;
            }
          }
          .pc13 {
            grid-column: 1 / 5;
            padding-top: 50px;
            picture {
              display: block;
              max-width: 798px;
              margin: 40px auto 0;
            }
          }
        }
      }
      .note {
        margin-top: 25px;
        font-size: 11px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 0.05em;
        color: #686868;
        text-align: right;
      }
    }

    .block-extra {
      margin-top: 110px;
      .unit {
        + .unit {
          margin-top: 68px;
        }
      }

      p {
        margin-top: 40px;
        font-weight: 700;
        @include pc {
          text-align: center;
        }
      }

      .list_wrap {
        margin-top: 38px;
        padding: 55px 40px 44px;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: #f5f5f5;
      }
      ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 15px 10px;
        max-width: 760px;
        margin-inline: auto;
        li {
          padding-left: 20px;
          font-weight: 700;
          line-height: 1;
          position: relative;
          letter-spacing: 0.01em;
          &::before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $theme-pink;
            position: absolute;
            left: 0;
            top: 2px;
          }
          &.wide {
            grid-column: 1 / 5;
          }
        }
      }
    }

    .block-guideline {
      margin-top: 100px;
      .guideline_list {
        margin-top: 50px;
        li {
          + li {
            margin-top: 20px;
          }
          &:nth-last-of-type(-n + 2) {
            display: none;
          }
        }
        a {
          position: relative;
          border: 1px solid #c1c1c1;
          border-radius: 15px;
          overflow: hidden;
          padding: 20px;
          box-sizing: border-box;
          figure {
            aspect-ratio: 65 / 46;
            overflow: hidden;
            border-radius: 15px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: $transition-base;
            }
          }
          dt {
            font-size: 25px;
            font-weight: 700;
            line-height: 1.3;
            transition: $transition-base;
          }
          dd {
            margin-top: 25px;
          }

          &:hover {
            figure img {
              transform: scale(1.1);
            }
            dt {
              color: $theme-pink;
            }
          }

          @include pc {
            display: grid;
            align-items: center;
            grid-template-columns: 325px 1fr;
            column-gap: 45px;
            .capsule_arrow {
              position: absolute;
              right: 27px;
              bottom: 22px;
            }
          }
        }
      }
    }

    .bn_wrap {
      margin-top: 80px;
      .container {
        max-width: 880px;
      }
      a {
        display: grid;
        place-content: center;
        position: relative;
        height: 200px;

        picture {
          position: absolute;
          inset: 0;
          display: block;
          border-radius: 15px;
          overflow: hidden;
          &::before {
            content: "";
            display: block;
            position: absolute;
            inset: 0;
            background-color: rgba(0, 0, 0, 0.4);
            mix-blend-mode: multiply;
            transition: $transition-base;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: $transition-base;
          }
        }
        h4 {
          z-index: 1;
          text-align: center;
          strong,
          span {
            display: block;
            line-height: 1;
            color: #fff;
          }
          strong {
            @include Viga;
            font-size: 40px;
            letter-spacing: 0.12em;
          }
          span {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.2em;
            margin-top: 20px;
          }
        }
        .round_arrow {
          position: absolute;
          width: 26px;
          right: 54px;
          top: 0;
          bottom: 0;
          margin: auto;
          transition: $transition-base;
        }
        &:hover {
          picture::before {
            background-color: rgba(0, 0, 0, 0.6);
          }
          .round_arrow {
            transform: translateX(5px);
          }
        }
      }
    }

    .block-entry {
      margin-top: 106px;
    }

    @include sp {
      .block-message {
        margin-top: 87px;
        .slash_grd {
          left: -185%;
          top: -200px;
        }

        .message_wrap {
          h3 {
            picture {
              left: -11px;
              top: -57px;
              width: 217px;
            }
            span {
              font-size: 20px;
              letter-spacing: 0.08em;
            }
          }
          p {
            margin-top: 22px;
            line-height: 1.9;
          }
        }

        .feature_wrap {
          margin: 28px -10px 0;
          border-radius: 15px;
          background: url(/assets/img/recruit/top/feature_bg_sp@2x.png) no-repeat center bottom / 100% auto;
          padding: 35px 25px 30px;
          .slash_ttl {
            font-size: 21px;
            margin-top: 26px;
          }
          p {
            margin-top: 20px;
          }
        }
      }

      .block-data {
        margin-top: 70px;
        padding: 65px 25px 75px;
        border-radius: 25px;
        .data_list {
          margin-top: 40px;
          grid-template-columns: repeat(2, 1fr);
          > li {
            padding: 33px 5px 40px;
            min-height: unset;
            h4 {
              font-size: 15px;
              padding-bottom: 9px;
              &::before {
                width: 31px;
              }
            }
            .data {
              margin-top: 20px;
            }
            .data + img {
              margin: 27px auto 0;
            }
            img {
              display: block;
            }
            .wide_wrap {
              grid-template-columns: 110px 1fr;
              margin: 20px 15px 0;
              table {
                tr {
                  + tr {
                    margin-top: 8px;
                  }
                }
                th {
                  padding-right: 5px;
                }
                td {
                  font-size: 21px;
                  font-weight: 700;
                  padding-left: 10px;
                  strong {
                    font-size: 25px;
                    letter-spacing: 0.02em;
                  }
                }
              }
            }
            .off_wrap {
              margin: 17px 10px 0;
              .rank_list {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                padding-bottom: 20px;
                border-bottom: 1px dotted rgb(55, 55, 55, 0.5);
                figure {
                  width: 41px;
                  height: 37px;
                  .rank1 {
                    grid-template-columns: 37px;
                  }
                  .rank2 {
                    grid-template-columns: 41px;
                  }
                  .rank3 {
                    grid-template-columns: 37px;
                  }
                }
                p {
                  margin-top: 13px;
                  font-size: 11px;
                  line-height: 1.3;
                  letter-spacing: 0.05em;
                }
              }
              .text_list {
                display: grid;
                grid-template-columns: 105px 60px 119px;
                row-gap: 14px;
                padding-top: 14px;
                justify-content: flex-start;
                li {
                  font-size: 11px;
                  &::before {
                    margin-right: 0;
                  }
                }
                @media(min-width:455px){
                  justify-content: center;
                }
              }
            }
          }
          .sp01 {
            grid-column: 1 / 3;
            img {
              width: 86px;
            }
            order: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .sp02 {
            order: 2;
            img {
              width: 57px;
            }
          }
          .sp03 {
            order: 3;
            img {
              width: 57px;
            }
          }
          .sp04 {
            order: 4;
            grid-column: 1 / 3;
            img {
              width: 54px;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .sp05 {
            order: 5;
            img {
              width: 52px;
            }
          }
          .sp06 {
            order: 6;
            img {
              width: 54px;
            }
          }
          .sp07 {
            order: 7;
            grid-column: 1 / 3;
            img {
              width: 57px;
            }
          }
          .sp08 {
            order: 8;
            grid-column: 1 / 3;
            img {
              width: 74px;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .sp09 {
            order: 9;
            img {
              width: 60px;
            }
          }
          .sp10 {
            order: 10;
            img {
              width: 64px;
            }
          }
          .sp11 {
            grid-column: 1 / 3;
            padding-top: 48px;
            order: 11;
          }
          .sp12 {
            grid-column: 1 / 3;
            order: 12;
            img {
              width: 82px;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .sp13 {
            grid-column: 1 / 3;
            padding-inline: 20px;
            order: 13;
            picture {
              margin-top: 20px;
              display: block;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
        .note {
          margin-top: 16px;
        }
      }

      .block-extra {
        margin-top: 72px;
        .unit {
          + .unit {
            margin-top: 40px;
          }
        }

        p {
          margin-top: 30px;
          font-weight: 700;
        }

        .list_wrap {
          margin: 13px -12px 0;
          padding: 28px 28px;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 10px 10px;
          li {
            min-width: 42%;
            padding-left: 16px;
            line-height: 1.3;
            &::before {
              top: 4px;
            }
          }
        }
      }

      .block-guideline {
        margin-top: 60px;

        .guideline_list {
          margin-top: 33px;
          li {
            + li {
              margin-top: 27px;
            }
          }
          a {
            display: block;
            padding: 20px 15px;
            dl {
              margin-top: 20px;
            }
            dt {
              font-size: 17px;
            }
            dd {
              margin-top: 13px;
              font-size: 14px;
              line-height: 1.7;
            }
            .capsule_arrow {
              margin-inline: auto 0;
            }
          }
        }
      }

      .bn_wrap {
        margin-top: 60px;
        a {
          // height: 130px;
          width: 100%;
          height: 100%;
          aspect-ratio: 63 / 26;
          picture {
            border-radius: 7px;
          }
          h4 {
            strong {
              font-size: 30px;
            }
            span {
              font-size: 12px;
              margin-top: 6px;
            }
          }
          .round_arrow {
            width: 19px;
            right: 20px;
          }
        }
      }

      .block-entry {
        margin-top: 58px;
      }
    }
  }
}
