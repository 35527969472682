.content_header {
  box-sizing: border-box;

  h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    row-gap: 10px;
    position: relative;
    z-index: 1;
    strong, span {
      display: block;
      line-height: 1;
    }
    strong {
      @include Viga;
      font-size: 55px;
      letter-spacing: .12em;
    }
    span {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: .2em;
    }
  }

  &_img {
    margin-left: 100px;
    position: relative;
    height: 367px;
    border-radius: 20px 0 0 20px;
    overflow: hidden;
    padding-left: 47px;
    box-sizing: border-box;
    picture {
      position: absolute;
      display: block;
      inset: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    h2 {
      strong, span {
        color: #fff;
      }
    }
  }
  &_carousel {
    position: relative;
    h2 {
      width: 480px;
      height: 157px;
      background-color: #fff;
      border-radius: 0 15px 15px 0;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      padding-left: 150px;
      box-sizing: border-box;
      strong, span {
        color: $theme-pink
      }
    }
  }
  &_text {
    height: 146px;
    h2 {
      align-items: center;
      strong, span {
        color: $theme-pink
      }
    }
    @include pc {
      padding-bottom: 18px;
      h2 {
        justify-content: flex-end;
      }
    }
  }
  
  @include sp {
    h2 {
      row-gap: 7px;
      strong {
        font-size: 28px;
        letter-spacing: .1em;
      }
      span {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: .2em;
      }
    }
    &_img {
      margin-left: 17px;
      height: 170px;
      border-radius: 10px 0 0 10px;
      padding-left: 27px;
      padding-bottom: 22px;
      h2 {
        justify-content: flex-end;
      }
    }
    &_carousel {
      h2 {
        width: 217px;
        height: 75px;
        border-radius: 0 10px 10px 0;
        top: auto;
        bottom: 20px;
        padding-left: 47px;
      }
    }
    &_text {
      height: 113px;
    }
  }
}