.is--home {
  .rwd002-header {
    position: absolute;
    padding-left: 0;
    &__logo a {
      display: grid;
      place-content: center;
      grid-template-columns: 115px;
      max-width: 200px;
      height: 115px;
      background-color: #fff;
      border-bottom-right-radius: 10px;
      overflow: hidden;
    }
    @include pc {
      padding-right: 15px;
      .rwd002-gnav-item__link {
        min-height: 115px;
        span {
          color: #fff;
          &::after {
            border-color: #fff;
          }
        }
      }
      .rwd002-gnav__contact a {
        width: 120px;
        height: 96px;
        border-radius: 5px;
        overflow: hidden;
      }
    }
    @include sp {
      background-color: transparent;
      height: 64px;
      padding-right: 13px;
      &__logo a {
        grid-template-columns: 86px;
        width: 100%;
        max-width: 124px;
        height: 64px;
      }
      &__drawerBtn {
        border-radius: 5px;
        overflow: hidden;
      }
    }
  }
  @include sp {
    #allbox {
      padding-top: 0;
    }
  }
  .slash {
    position: absolute;
    display: block;
  }

  .top_sec_ttl {
    strong, span {
      display: block;
      line-height: 1;
    }
    strong {
      font-size: 48px;
      @include Viga;
      letter-spacing: .12em;
    }
    span {
      font-size: 14px;
      font-weight: 700;
      margin-top: 12px;
      letter-spacing: .2em;
    }
  }
  .rwd002-container {
    margin-top: 0;
  }

  .mainvisual {
    width: 100%;
    height: 100svh;
    position: relative;
    z-index: 1;

    .slide_wrap {
      position: relative;
      height: 100%;
      &::before {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        z-index: 2;
        pointer-events: auto;
      }
    }

    .mv_slide {
      position: absolute;
      inset: 0;
      .swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .swiper-slide-active img,
      .swiper-slide-duplicate-active img,
      .swiper-slide-prev img {
        transform: scale(1.06);
        animation: zoomOut 6s linear 0s 1 normal both;
      }
    }

    .swiper-pagination {
      right: 20px;
      .swiper-pagination-bullet {
        width: 2px;
        height: 16px;
        opacity: 1;
        background-color: #fff;
        border-radius: 0;
        margin-block: 3px;
        &.swiper-pagination-bullet-active {
          background-color: $theme-pink;
        }
      }
    }

    .catch_wrap {
      position: absolute;
      left: 130px;
      bottom: 30%;
      z-index: 1;
      .catch {
        width: 342px;
        clip-path: inset(0 100% 0 0);
      }
      .catch2 {
        margin-top: 10px;
      }
      .text {
        color: #fff;
        margin-top: 24px;
        font-size: 13px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: .16em;
        opacity: 0;
        transform: translateY(10px);
      }
    }

    .news_wrap {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 10;
      background-color: #F5F5F5;
      border-top-left-radius: 10px;
      width: min(100%, 810px);
      padding-inline: 57px 51px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      column-gap: 35px;
      height: 73px;
      opacity: 0;
      transform: translateY(5px);

      .ttl {
        @include Viga;
        font-size: 20px;
        letter-spacing: .08em;
        line-height: 1;
        color: $theme-pink;
      }
      a {
        padding-right: 45px;
        width: 100%;
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 23px;
          aspect-ratio: 23 / 5;
          background: url(/assets/img/home/mv_arrow.svg) no-repeat 0 / contain;
          overflow: hidden;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          transition: $transition-base;
        }
        .info {
          display: flex;
          align-items: center;
          line-height: 1;
          .date {
            font-size: 12px;
            letter-spacing: .12em;
            @include Roboto(400);
          }
          .cate {
            font-size: 12px;
            font-weight: 700;
            letter-spacing: .08em;
            &::before {
              content: '|';
              margin-inline: 6px;
            }
          }
        }
        .article_ttl {
          line-height: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;
          font-weight: 700;
          letter-spacing: .08em;
          transition: $transition-base;
          font-weight: 700;
          line-height: 1;
          letter-spacing: .08em;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        @include pc {
          display: flex;
          align-items: center;
          column-gap: 15px;
        }

        &:hover {
          &::before {
            transform: translateX(5px);
          }
          .article_ttl {
            color: $theme-pink;
          }
        }
      }
    }

    .scroll {
      width: 154px;
      font-size: 10px;
      @include Viga;
      letter-spacing: .2em;
      color: $theme-pink;
      line-height: 1;
      position: absolute;
      left: 42px;
      bottom: 146px;
      transform-origin: left top;
      transform: rotate(90deg);
      z-index: 1;
      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100px;
        height: 1px;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &::before {
        background-color: #dadada;
      }
      &::after {
        background-color: $theme-pink;
        animation: scrollLine 2s ease-out infinite;
      }
    }
    
    @keyframes scrollLine {
      0% {
        transform-origin: left;
        transform: scaleX(0);
      }
      50% {
        transform-origin: left;
        transform: scaleX(1);
      }
      51% {
        transform-origin: right;
        transform: scaleX(1);
      }
      100% {
        transform-origin: right;
        transform: scaleX(0);
      }
    }

    &.loaded {
      .catch_wrap {
        .catch {
          clip-path: inset(0);
        }
        .catch1 {
          transition: clip-path .3s ease-out .5s;
        }
        .catch2 {
          transition: clip-path .3s ease-out .6s;
        }
        .text {
          opacity: 1;
          transform: translateY(0);
          transition: opacity .5s ease-out .8s, transform .5s ease-out .8s;
        }
      }
      .news_wrap {
        opacity: 1;
        transform: translateY(0);
        transition: opacity .5s ease-out 1.1s, transform .5s ease-out 1.1s;
      }
    }

  }

  @keyframes zoomOut {
    from { transform: scale(1.1);}
    to   { transform: scale(1);}
  }

  .block-business {
    padding-block: 135px 90px;
    background-color: #F5F5F5;
    .desc {
      h3 {
        font-size: 29px;
        font-weight: 700;
        line-height: 1.7;
        letter-spacing: .08em;
        margin-top: 44px;
      }
      p {
        font-weight: 700;
        line-height: 2.1;
        margin-top: 15px;
        max-width: 410px;
      }
    }

    .btn_wrap {
      margin-top: 43px;
    }

    .link_list {
      a {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 370px;
        position: relative;
        border-radius: 15px;
        overflow: hidden;
        padding: 25px 17px 25px 20px;
        box-sizing: border-box;
        z-index: 1;
        &::before {
          content: '';
          display: block;
          position: absolute;
          inset: 0;
          background-color: rgba(0, 0, 0, 0);
          mix-blend-mode: multiply;
          transition: $transition-base;
        }
        picture {
          position: absolute;
          inset: 0;
          z-index: -1;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        dl {
          z-index: 1;
          dt, dd {
            color: #fff;
            font-weight: 700;
          }
          dt {
            line-height: 1;
            letter-spacing: .2em;
            font-size: 12px;
          }
          dd {
            letter-spacing: .12em;
            margin-top: 10px;
            font-size: 18px;
            line-height: 1.3;
          }
        }
        .arrow_wrap {
          display: grid;
          place-content: center;
          grid-template-columns: 12px;
          width: 28px;
          aspect-ratio: 1 / 1;
          border-radius: 50%;
          border: 1px solid #fff;
          box-sizing: border-box;
          transition: $transition-base;
          z-index: 1;
          .arrow {
            width: 12px;
          }
        }
        &:hover {
          &::before {
            background-color: rgba(0, 0, 0, .45);
          }
          .arrow_wrap {
            transform: translateX(5px);
          }
        }
      }
    }

    @include pc {
      .container {
        display: grid;
        grid-template-columns: 50% 1fr;
        column-gap: 74px;
        max-width: 1270px;
      }
      .slash1 {
        width: 605px;
        left: -81px;
        top: -60px;
      }
      .slash2 {
        width: 256px;
        right: -141px;
        top: -132px;
      }
      .desc {
        order: 2;
        margin-top: 33px;
      }
  
      .link_list {
        order: 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        li {
          &:nth-of-type(1){
            order: 2;
          }
          &:nth-of-type(2){
            order: 1;
            margin-top: 112px;
          }
        }
      }
    }
  }

  .block-works {
    padding-block: 109px 104px;
    .top_sec_ttl {
      text-align: center;
    }

    .slide_wrap {
      margin-top: 62px;
      position: relative;
      .swiper-slide {
        width: 323px;
        a {
          position: relative;
          display: block;
          figure {
            overflow: hidden;
            aspect-ratio: 323 / 233;
            border-radius: 15px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: .3s;
            }
          }
          .text_wrap {
            margin-top: 25px;
            padding-inline: 3px;
            .cate {
              font-size: 12px;
              font-weight: 500;
              letter-spacing: .05em;
              color: #808080;
              line-height: 1;
              display: flex;
              align-items: center;
              column-gap: 3px;
              &::before {
                content: '';
                display: inline-block;
                width: 8px;
                aspect-ratio: 1 / 1;
                border-radius: 50%;
                background: $theme-grd;
              }
            }
            .ttl {
              font-weight: 700;
              line-height: 1;
              letter-spacing: .15em;
              margin-top: 14px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              transition: $transition-base;
            }
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
            .ttl {
              color: $theme-pink;
            }
          }
        }
      }
    }

    .item_wrap {
      margin-top: 48px;
      display: grid;
      grid-template-columns: 1fr 78px;
      column-gap: 18px;
      align-items: center;
    }

    .swiper-pagination {
      height: 2px;
      inset: auto;
      position: relative;
      background-color: #CACDCF;
      .swiper-pagination-progressbar-fill {
        background-color: $theme-pink;
      }
    }

    .arrow_wrap {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 35px);
      justify-content: space-between;
      width: 100%;
      height: 35px;
      .swiper-button-prev, .swiper-button-next {
        aspect-ratio: 1 / 1;
        width: 100%;
        height: unset;
        border: 1px solid #808080;
        border-radius: 50%;
        box-sizing: border-box;
        position: relative;
        inset: auto;
        margin: 0;
        &::after {
          content: '';
          width: 6px;
          height: 6px;
          inset: 0;
          margin: auto;
          border-right: 1px solid #808080;
          border-bottom: 1px solid #808080;
          transition: .3s;
        }
      }
      .swiper-button-prev {
        &::after {
          transform: rotate(135deg);
        }
      }
      .swiper-button-next {
        &::after {
          transform: rotate(-45deg);
        }
      }
    }

    .btn_wrap {
      a {
        display: flex;
        column-gap: 11px;
        align-items: center;
        span {
          @include Viga;
          font-size: 12px;
          letter-spacing: .12em;
          transition: $transition-base;
        }
        &:hover span {
          color: $theme-pink;
        }
      }
    }

    @include pc {
      .container {
        position: relative;
      }
      .slash {
        width: 178px;
        right: calc(50% + 101px);
        top: -182px;
      }
      .slide_wrap {
        margin-right: calc(50% - 50vw);
      }
      .btn_wrap {
        position: absolute;
        top: 70px;
        right: 50px;
      }
    }
  }

  .block-company {
    padding-top: 104px;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 446px;
      background-color: #F5F5F5;
      left: 0;
      top: 0;
      z-index: -1;
    }

    .top_sec_ttl {
      text-align: center;
    }

    .link_list {
      margin-top: 52px;
      a {
        display: grid;
        place-content: center;
        place-items: center;
        height: 420px;
        position: relative;
        padding-top: 21px;
        box-sizing: border-box;
        z-index: 1;
        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          inset: 0;
          background-color: #000;
          transition: $transition-base;
          z-index: -1;
        }
        &::before {
          background: $theme-grd;
          opacity: 0;
        }
        &::after {
          background: #000;
          opacity: .3;
        }
        picture {
          position: absolute;
          inset: 0;
          overflow: hidden;
          z-index: -2;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        h3 {
          color: #fff;
          position: relative;
          padding-bottom: 17px;
          font-size: 25px;
          font-weight: 700;
          letter-spacing: .14em;
          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 36px;
            height: 1px;
            background-color: #fff;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
          }
        }
        p {
          color: #fff;
          font-size: 13px;
          font-weight: 700;
          line-height: 1.6;
          letter-spacing: .14em;
          margin-top: 18px;
        }
        .arrow_btn {
          position: absolute;
          right: 33px;
          bottom: 31px;
          width: 60px;
          height: 20px;
          border-radius: 10px;
          display: grid;
          place-content: center;
          background-color: #fff;
          .arrow {
            width: 14px;
          }
        }
        
        &:hover {
          &::before {
            opacity: 0.7;
          }
          &::after {
            opacity: 0;
          }
        }
      }
    }

    @include pc {
      .container {
        max-width: 1300px;
      }
      .slash1 {
        width: 452px;
        right: 8px;
        top: -10px;
      }
      .slash2 {
        width: 636px;
        left: -132px;
        bottom: -246px;
      }
      .link_list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border-radius: 15px;
        overflow: hidden;
      }
    }
  }

  .block-recruit {
    padding-block: 208px 94px;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: calc(100% - 500px);
      background-color: #F5F5F5;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
    .slash_grd {
      left: -53%;
      top: 770px;
    }
    .container {
      margin-top: 98px;
    }
    .desc {
      position: relative;
      h2 {
        position: relative;
        left: -77px;
        width: 286px;
      }
      h3 {
        font-size: 29px;
        line-height: 1.5;
        letter-spacing: .11em;
      }
      p {
        font-weight: 700;
        line-height: 2.1;
        margin-top: 21px;
      }
    }

    .link_list {
      a {
        picture {
          display: block;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: $transition-base;
          }
        }
        p {
          display: grid;
          place-content: center;
          place-items: center;
          background-color: #fff;
          row-gap: 7px;
          strong, span {
            line-height: 1;
            transition: $transition-base
          }
          strong {
            @include Viga;
            font-size: 32px;
            letter-spacing: .12em;
          }
          span {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: .2em;
          }
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
          p {
            strong, span {
              color: $theme-pink;
            }
          }
        }
      }
    }

    .btn_wrap {
      margin-top: 60px;
      .link_btn {
        justify-content: center;
        width: min(100%, 400px);
        height: 94px;
        border-radius: 47px;
        margin-inline: auto;
        column-gap: 12px;
        .icon {
          width: 19px;
          position: relative;
          bottom: 1px;
        }
        span {
          font-size: 18px;
          font-weight: 700;
          letter-spacing: .18em;
        }
        .arrow {
          right: 60px;
        }
        &::after {
          background: #333;
        }
      }
    }

    @include pc {
      .container {
        display: grid;
        grid-template-columns: 42% 1fr;
        align-items: center;
        column-gap: 38px;
        max-width: 1300px;
      }
      .desc {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        max-width: 387px;
        margin-inline: auto 0;
      }
  
      .link_list {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        overflow: hidden;
        border-radius: 15px;
        box-shadow: 10px 10px 10px rgba(0, 0, 0, .1);
        picture {
          height: 344px;
        }
        p {
          height: 107px;
        }
        li:nth-of-type(2) p {
          box-sizing: border-box;
          border-left: 1px solid #BCBCBC;
        }
      }
  
      .btn_wrap {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
      }
    }
  }

  .block-news {
    padding-top: 126px;
    .news_list {
      li {
        + li {
          border-top: 1px solid #B7B8B8;
          padding-top: 28px;
          margin-top: 14px;
        }
      }
      a {
        padding-inline: 16px;
        display: block;
        .info {
          display: flex;
          align-items: center;
          line-height: 1;
          span {
            line-height: 1;
            font-size: 12px;
            color: #808080;
          }
          .date {
            @include Roboto(400);
            letter-spacing: .15em;
          }
          .cate {
            font-weight: 700;
            letter-spacing: .08em;
            &::before {
              content: '|';
              margin-inline: 6px;
            }
          }
        }
        .ttl {
          font-size: 16px;
          line-height: 1.6;
          font-weight: 700;
          letter-spacing: .12em;
          transition: $transition-base;
          margin-top: 9px;
        }

        &:hover {
          .ttl {
            color: $theme-pink;
          }
        }
      }
    }

    .btn_wrap {
      a {
        display: flex;
        column-gap: 11px;
        align-items: center;
        span {
          @include Viga;
          font-size: 12px;
          letter-spacing: .12em;
          transition: $transition-base;
        }
        &:hover span {
          color: $theme-pink;
        }
      }
    }

    @include pc {
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 300%;
        background-color: #fff;
        left: 0;
        top: 0;
        opacity: .4;
        z-index: -1;
      }
      .container {
        display: grid;
        grid-template-columns: 256px 1fr;
        position: relative;
      }
      .top_sec_ttl {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        strong {
          font-size: 45px;
        }
      }
      .news_list {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }
      .btn_wrap {
        position: absolute;
        left: 50px;
        top: 119px;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    .rwd002-block.is-head {
      order: 6;
    }
  }

  .bn_area {
    margin-top: 116px;
    @include pc {
      .container {
        max-width: 750px;
      }
      a:hover {
        .bn_img {
          opacity: 0;
        }
        .bn_img_h {
          opacity: 1;
        }
      }
    }
    a {
      display: block;
      position: relative;
      .bn_img {
        transition: $transition-base;
      }
      .bn_img_h {
        position: absolute;
        inset: 0;
        opacity: 0;
        transition: $transition-base;
      }
      dl {
        position: absolute;
        left: 60px;
        top: 40px;
        dt {
          font-size: 25px;
          font-weight: 700;
          letter-spacing: .18em;
          line-height: 1.2;
        }
        dd {
          color: $theme-pink;
          line-height: 1;
          @include Viga;
          letter-spacing: .12em;
          margin-top: 17px;
        }
      }
      .arrow_wrap {
        display: grid;
        place-content: center;
        grid-template-columns: 14px;
        width: 59px;
        height: 22px;
        border-radius: 11px;
        background: $theme-grd;
        position: absolute;
        right: 17px;
        bottom: 67px;
        .arrow {
          width: 14px;
          transition: $transition-base;
        }
      }
    }
  }

  @include sp {
    .top_sec_ttl {
      strong {
        font-size: 36px;
      }
      span {
        font-size: 13px;
        margin-top: 7px;
      }
    }

    .mainvisual {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .catch_wrap {
        left: 25px;
        bottom: 125px;
        .catch {
          width: 211px;
        }
        .catch2 {
          margin-top: 6px;
        }
        .text {
          margin-top: 19px;
          font-size: 12px;
          line-height: 1.7;
        }
      }

      .news_wrap {
        padding-inline: 22px 16px;
        border-radius: 10px 10px 0 0;
        height: 77px;
        column-gap: 20px;
        .ttl {
          font-size: 18px;
        }
        a {
          display: block;
          padding-right: 30px;
          overflow: hidden;
          &::before {
            width: 16px;
          }
          .info {
            .date {
              font-size: 10px;
            }
            .cate {
              font-size: 10px;
              &::before {
                margin-inline: 6px;
              }
            }
          }
          .article_ttl {
            margin-top: 6px;
            line-height: 1;
            font-size: 13px;
          }
        }
      }

      .scroll {
        width: 108px;
        left: auto;
        right: -81px;
        bottom: 189px;
        &::before, &::after {
          width: 56px;
        }
      }
    }

    .block-business {
      padding-block: 54px 71px;
      .slash1 {
        width: 348px;
        left: -172px;
        bottom: -246px;
      }
      .slash2 {
        width: 159px;
        right: -69px;
        top: -62px;
      }

      .desc {
        position: relative;
        h3 {
          font-size: 20px;
          line-height: 1.6;
          margin-top: 29px;
        }
        p {
          line-height: 1.9;
          letter-spacing: .08em;
          margin-top: 13px;
        }
      }
  
      .link_list {
        margin-top: 30px;
        li + li {
          margin-top: 12px;
        }
        a {
          height: 193px;
          border-radius: 15px;
          padding-block: 22px;
          dl {
            dd {
              font-size: 16px;
              line-height: 1.4;
            }
          }
        }
      }

      .btn_wrap {
        margin-top: 31px;
      }
    }
  
    .block-works {
      padding-block: 75px 69px;
      .slash {
        width: 120px;
        right: 4px;
        top: -127px;
      }
      .top_sec_ttl {
        text-align: center;
      }
      .slide_wrap {
        margin-top: 33px;
        margin-right: -27px;
        .swiper-slide {
          a {
            .text_wrap {
              margin-top: 17px;
              padding-inline: 5px;
              .cate {
                column-gap: 5px;
              }
            }
          }
        }
      }
  
      .item_wrap {
        margin-top: 22px;
      }
  
      .swiper-pagination {
        height: 1px;
      }
  
      .btn_wrap {
        margin-top: 20px;
        a {
          justify-content: center;
        }
      }
    }
  
    .block-company {
      padding-top: 78px;
      &::before {
        height: 288px;
      }
      .container {
        padding-inline: 18px;
      }
      .slash1 {
        width: 254px;
        right: -15px;
        top: 41px;
      }
      .slash2 {
        width: 355px;
        left: -84px;
        bottom: -128px;
      }

      .link_list {
        margin-top: 35px;
        li + li {
          margin-top: 12px;
        }
        a {
          height: 210px;
          border-radius: 10px;
          overflow: hidden;
          padding-top: 0;
          h3 {
            padding-bottom: 8px;
            font-size: 20px;
          }
          p {
            line-height: 1.4;
            margin-top: 17px;
          }
          .arrow_btn {
            right: 18px;
            bottom: 18px;
          }
        }
      }
    }
  
    .block-recruit {
      padding-block: 73px 69px;
      position: relative;
      &::before {
        height: calc(100% - 130px);
      }
      .container {
        margin-top: 0;
      }
      .slash_grd {
        left: -121%;
        top: 263px;
      }
      .desc {
        margin-top: -19px;
        h2 {
          width: 219px;
          left: -15px;
        }
        h3 {
          font-size: 20px;
          letter-spacing: .08em;
          margin-top: 18px;
          @include max(380px){
            font-size: 19px;
          }
        }
        p {
          margin-top: 20px;
          line-height: 1.9;
        }
      }
  
      .link_list {
        margin-top: 30px;
        li + li{
          margin-top: 10px;
        }
        a {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          height: 122px;
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
          p {
            row-gap: 5px;
            strong {
              font-size: 25px;
            }
            span {
              font-size: 12px;
            }
          }
        }
      }
  
      .btn_wrap {
        margin-top: 34px;
        .link_btn {
          width: 274px;
          height: 65px;
          border-radius: 33px;
          .icon {
            width: 16px;
          }
          span {
            font-size: 15px;
          }
          .arrow {
            right: 16px;
          }
        }
      }
    }

    .block-news {
      padding-top: 76px;
      .top_sec_ttl {
        text-align: center;
      }

      .news_list {
        margin-top: 43px;
        li {
          + li {
            padding-top: 23px;
            margin-top: 20px;
          }
        }
        a {
          padding-inline: 0;
          .info {
            .cate::before {
              margin-inline: 6px;
            }
          }
          .ttl {
            font-size: 15px;
            line-height: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-top: 9px;
          }
        }
      }
  
      .btn_wrap {
        margin-top: 40px;
        a {
          justify-content: flex-end;
        }
      }
    }

    .bn_area {
      margin-top: 40px;
      margin-right: -30px;
      .container{
        display: flex;
        justify-content: center;
      }
      a {
        dl {
          left: 6vw;
          top: 6.5vw;
          dt {
            font-size: 4.7vw;
            @media(min-width:800px){
              font-size: 4vw;
            }
          }
          dd {
            margin-top: 2vw;
            font-size: 3vw;
          }
        }
        .arrow_wrap {
          grid-template-columns: 7px;
          width: 29px;
          height: 11px;
          border-radius: 6px;
          // right: 39px;
          // bottom: 9px;
          right: 11%;
          bottom: 5%;
        }
      }
    }
  }
}