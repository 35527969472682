footer {
  margin-top: 93px;
  position: relative;

  .contact_list {
    border-top: 1px solid #A2A2A2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    li + li {
      border-left: 1px solid #A2A2A2;
      box-sizing: border-box;
    }
    a {
      display: grid;
      place-content: center;
      place-items: center;
      height: 265px;
      figure {
        width: 65px;
        height: 65px;
        display: grid;
        place-content: center;
      }
      .contact {
        grid-template-columns: 62px;
      }
      .entry {
        grid-template-columns: 1fr;
      }
      p {
        text-align: center;
        margin-top: 22px;
        strong, small {
          display: block;
          line-height: 1;
        }
        strong {
          @include Viga;
          font-size: 40px;
          letter-spacing: .12em;
        }
        small {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: .2em;
          margin-top: 15px;
        }
      }
      &:hover {
        background-color: rgba(255, 0, 121, .2);
      }
    }
  }

  .foot_content {
    background-color: #333;
    padding-block: 104px 42px;
    p, li, a {
      color: #fff;
    }
    a:hover {
      color: $theme-pink;
    }

    .info_wrap {
      p {
        line-height: 1;
      }
      .logo {
        width: 292px;
        a:hover {
          opacity: .7;
        }
      }
      .address {
        font-size: 12px;
        letter-spacing: .21em;
        margin-top: 16px;
      }
      .map {
        margin-top: 7px;
        a {
          font-size: 12px;
          letter-spacing: .13em;
          display: flex;
          align-items: center;
          column-gap: 3px;
          border-bottom: 1px solid #fff;
          max-inline-size: max-content;
          &:hover {
            border-color: $theme-pink;
          }
        }
      }
      .tel {
        margin-top: 23px;
        a {
          display: flex;
          align-items: center;
          column-gap: 4px;
          @include Roboto(700);
          font-size: 23px;
          letter-spacing: .13em;
          line-height: 1;
          img {
            width: 25px;
          }
        }
      }
      .fax {
        @include Roboto(500);
        font-size: 15px;
        letter-spacing: .13em;
        margin-top: 9px;
      }
      .time {
        font-size: 12px;
        letter-spacing: .21em;
        margin-top: 9px;
      }
      .iso {
        display: block;
        width: 75px;
        margin-top: 16px;
      }
      @include pc {
        @media (hover: none){
          .fax {
            margin-top: 6px;
          }
          .time {
            margin-top: 6px;
          }
          .iso {
            margin-top: 14px;
          }
        }
      }
    }

    nav {
      li, a {
        font-size: 14px;
        letter-spacing: .13em;
        line-height: 1;
      }
      .main_list {
        > li {
          > a {
            &::before {
              content: '-';
              margin-right: 2px;
            }
          }
        }
      }
    }

    .bottom_info {
      p, a {
        font-size: 10px;
        letter-spacing: .15em;
        line-height: 1;
        opacity: .7;
      }
    }

    @include pc {
      .container {
        display: grid;
        grid-template-columns: 366px 1fr;
        align-items: center;
        row-gap: 43px;
        max-width: 1170px;
      }
  
      nav {
        .main_list {
          display: grid;
          grid-template-columns: 39% 18% 19% 24%;
          row-gap: 15px;
          > li {
            &:nth-of-type(2){
              grid-column: 1 / 2;
              grid-row: 2 / 5;
            }
            &:nth-of-type(4){
              grid-column: 2 / 3;
              grid-row: 2 / 5;
            }
            &:nth-of-type(5){
              grid-column: 3 / 4;
              grid-row: 1 / 5;
            }
          }
        }
        .sub_list {
          margin-top: 15px;
          margin-left: 10px;
          li {
            + li {
              margin-top: 15px;
            }
          }
        }
      }
  
      .bottom_info {
        grid-column: 1 / 3;
        display: flex;
        justify-content: center;
        align-items: center;
        .login {
          font-size: 11px;
          a {
            font-size: 11px;
          }
          &::before {
            content: '/';
            margin-inline: 10px;
          }
        }
      }
    }
  }

  @include sp {
    margin-top: 105px;

    .contact_list {
      a {
        height: 160px;
        figure {
          width: 45px;
          height: 43px;
        }
        .contact {
          grid-template-columns: 45px;
        }
        .entry {
          grid-template-columns: 43px;
        }
        p {
          margin-top: 13px;
          strong {
            font-size: 23px;
          }
          small {
            font-size: 11px;
            margin-top: 8px;
          }
        }
      }
    }

    .foot_content {
      padding-block: 53px 35px;
      .info_wrap {
        p {
          text-align: center;
        }
        .logo {
          width: 270px;
          margin-inline: auto;
        }
        .address {
          margin-top: 25px;
        }
        .map {
          margin-top: 7px;
          a {
            margin-inline: auto;
          }
        }
        .tel {
          margin-top: 23px;
          a {
            justify-content: center;
          }
        }
        .fax {
          margin-top: 9px;
        }
        .time {
          margin-top: 9px;
        }
        .iso {
          margin: 16px auto 0;
        }
      }

      nav {
        margin-top: 23px;
        a {
          font-size: 14px;
          letter-spacing: .13em;
          line-height: 1;
        }
        .main_list {
          border-top: 1px solid rgba(255, 255, 255, .4);
          > li {
            padding-inline: 3px;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(255, 255, 255, .4);
            > a {
              display: flex;
              align-items: center;
              height: 35px;
            }
          }
          .slide_list {
            display: grid;
            grid-template-columns: 1fr 35px;
            .slide_trigger {
              display: block;
              position: relative;
              height: 35px;
              background-color: transparent;
              border: none;
              &::before, &::after {
                content: '';
                display: block;
                position: absolute;
                width: 9px;
                height: 1px;
                background-color: #fff;
                inset: 0;
                margin: auto;
              }
              &::after {
                transform: rotate(-90deg);
                transition: $transition-base;
              }
              &.open::after {
                transform: rotate(0);
              }
            }
          }
        }
        .sub_list {
          grid-column: 1 / 3;
          padding: 5px 0 20px 7px;
          display: none;
          li {
            line-height: 1;
            & + li {
              margin-top: 12px;
            }
          }
          a {
            font-size: 13px;
          }
        }
      }

      .bottom_info {
        text-align: center;
        margin-top: 34px;
        .login {
          margin-top: 9px;
        }
      }
    }  
  }
}

.pagetop {
  position: absolute;
  right: 68px;
  top: -48px;
  z-index: 1;
  a {
    display: grid;
    place-content: center;
    row-gap: 6px;
    text-align: center;
    @include Viga;
    font-size: 12px;
    line-height: 1;
    letter-spacing: .12em;
    color: $theme-pink;
    img {
      width: 40px;
      transition: .5s;
    }
    &:hover img {
      transform: translateY(-3px);
    }
  }

  @include sp {
    right: 0;
    left: 0;
    top: -60px;
    margin: auto;
  }
}