body.is--business.is--article-3 {
  .block-lead {
    margin-top: 62px;
    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 2;
      letter-spacing: .11em;
      @include pc {
        text-align: center;
      }
    }
    .anchor_list {
      margin-top: 75px;
      max-width: 833px;
    }
  }

  .block-content {
    margin-top: 98px;
    .point_list {
      margin-top: 41px;
      @include pc {
        .point_card:first-of-type .text {
          max-width: 410px;
        }
      }
    }

    .content_list {
      margin-top: 17px;
    }
  }

  .bros_link_wrap.bottom {
    margin-top: 59px;
  }

  @include sp {
    .block-lead {
      margin-top: 41px;
      p {
        font-size: 15px;
        line-height: 1.7;
        letter-spacing: .08em;
      }
      .anchor_list {
        margin-top: 31px;
      }
    }
  
    .block-content {
      margin-top: 46px;
      .point_list {
        margin-top: 26px;
        img{
          width: 100%;
        }
      }
  
      .content_list {
        margin-top: 75px;
        img{
          width: 100%;
        }
      }
    }
  
    .bros_link_wrap.bottom {
      margin-top: 65px;
    }
  }
}