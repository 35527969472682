body.is--company.is--article-1 {
  .rwd002 {
    &-container {
      margin: 0;
      padding: 0;
    }
    &-detail {
      margin: 0;
      padding: 0;
      &__entry {
        max-width: unset;
      }
    }
  }

  .anchor_wrap {
    margin-top: 32px;
  }
  @include pc {
    .anchor_list {
      max-width: 810px;
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .block-philosophy {
    margin-top: 93px;
    text-align: center;
    h4 {
      margin-top: 32px;
      font-size: 34px;
      line-height: 1;
    }
    p {
      margin-top: 34px;
      font-weight: 700;
    }
  }

  .block-greet {
    padding-block: 90px;
    margin-top: 70px;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      background-color: #f5f5f5;
      border-radius: 100px;
      overflow: hidden;
      z-index: -1;
    }
    .card {
    }
    .img_wrap {
      picture {
        display: block;
      }
      .president {
        border-radius: 15px;
        overflow: hidden;
      }
      .slash {
        position: absolute;
        width: 365px;
        z-index: -1;
        left: -142px;
        top: -123px;
      }
      .name {
        display: flex;
        align-items: flex-end;
        column-gap: 18px;
        font-size: 14px;
        line-height: 1;
        span {
          font-family: "游明朝体", serif;
          font-size: 22px;
          font-weight: 800;
          line-height: 1;
        }
      }
    }

    .desc {
    }
    p {
      margin-top: 35px;
    }

    @include pc {
      .container {
        max-width: 1114px;
      }
      .card {
        padding-left: 426px;
        box-sizing: border-box;
        position: relative;
        padding-block: 20px 30px;
        min-height: 428px;
        .president {
          position: absolute;
          width: 359px;
          left: 0;
          top: 0;
        }
        .name {
          position: absolute;
          right: 0;
          bottom: -15px;
        }
        .sec_ttl {
          text-align: left;
        }
      }
    }
  }

  .block-certification {
    margin-top: 96px;
    .unit {
      @include pc {
        display: grid;
        grid-template-columns: 212px 1fr;
        column-gap: 44px;
        max-width: 715px;
        margin: 45px auto 0;
        align-items: center;
      }
    }
    picture {
    }
    p {
    }
  }

  .block-info {
    margin-top: 135px;
    .base_table {
      margin-top: 35px;
      .round_list {
        li {
          padding-left: 14px;
          box-sizing: border-box;
          position: relative;
          + li {
            margin-top: 10px;
          }
          &::before {
            content: "";
            position: absolute;
            width: 10px;
            aspect-ratio: 1 / 1;
            overflow: hidden;
            border-radius: 50%;
            background-color: $font-color;
            left: 0;
            top: 6px;
          }
        }
      }
      .col_list {
        li {
          line-height: 1;
          &::before {
            display: none;
          }
        }
      }
      p + p {
        margin-top: 10px;
      }
      .note {
        font-size: 13px;
        line-height: 1;
        margin-top: 19px;
      }
    }
    @include pc {
      .container {
        max-width: 950px;
      }
      .col_list {
        display: grid;
        grid-template-columns: 31% 40% 29%;
        row-gap: 16px;
        @include max(1000px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .facility_list {
      margin-top: 24px;
      li {
        + li {
          margin-top: 26px;
        }
        @include pc {
          display: grid;
          grid-template-columns: 284px 1fr;
          column-gap: 49px;
          align-items: center;
        }
      }
      picture {
        display: block;
        border-radius: 15px;
        overflow: hidden;
      }
      .desc {
      }
      a {
        color: $font-color;
      }
      h4 {
        font-size: 15px;
        letter-spacing: 0.09em;
        line-height: 1;
      }
      table {
        margin-top: 16px;
        display: block;
        tbody {
          display: block;
        }
        tr {
          display: grid;
          grid-template-columns: 80px 1fr;
          column-gap: 8px;
          + tr {
            margin-top: 3px;
          }
        }
        th,
        td {
          line-height: 1.6;
          letter-spacing: 0.09em;
        }
        th {
        }
        td {
        }
      }
      .map {
        margin-top: 15px;
        a {
          font-size: 12px;
          letter-spacing: 0.13em;
          display: flex;
          align-items: center;
          column-gap: 5px;
          padding-bottom: 5px;
          line-height: 1;
          border-bottom: 1px solid $font-color;
          max-inline-size: max-content;
          &:hover {
            color: $theme-pink;
            border-bottom-color: $theme-pink;
          }
        }
      }
    }
  }

  .map_wrap {
    margin-top: 156px;
    ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 438px;
    }
    li {
      position: relative;
      overflow: hidden;
      &::before {
        content: "";
        display: block;
        position: absolute;
        inset: 0;
        background-color: #000;
        pointer-events: none;
      }
      &:nth-of-type(1)::before {
        opacity: 0.2;
      }
      &:nth-of-type(2)::before {
        opacity: 0.3;
      }
    }
    iframe {
      width: 100%;
      height: calc(100% + 360px);
      margin-top: -170px;
    }
  }

  .block-history {
    background-color: #f5f5f5;
    border-radius: 0 0 100px 100px;
    padding-block: 85px 120px;
    overflow: hidden;
    .history_table {
      display: block;
      margin: 50px auto 0;
      max-width: 575px;
      .tbody {
        display: block;
      }
      tr {
        padding-left: 35px;
        box-sizing: border-box;
        position: relative;
        @include pc {
          display: grid;
          grid-template-columns: 101px 1fr;
          align-items: baseline;
        }
        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          background-color: $theme-pink;
          top: 10px;
        }
        &::before {
          width: 9px;
          aspect-ratio: 1 / 1;
          overflow: hidden;
          border-radius: 50%;
          left: 0;
        }
        &:not(:last-of-type) {
          padding-bottom: 24px;
          &::after {
            width: 1px;
            height: 100%;
            left: 4px;
          }
        }
      }
      th,
      td {
        display: block;
        letter-spacing: 0.09em;
      }
      th {
        color: $theme-pink;
        @include Viga;
        line-height: 1;
      }
      td {
        line-height: 1.7;
      }
    }
  }

  @include sp {
    .anchor_wrap {
      margin-top: 32px;
    }

    .block-philosophy {
      margin-top: 47px;
      h4 {
        margin-top: 32px;
        font-size: 25px;
      }
      p {
        margin-top: 21px;
      }
    }

    .block-greet {
      padding-block: 58px 67px;
      margin-top: 37px;
      &::before {
        border-radius: 25px;
      }
      .img_wrap {
        position: relative;
        margin: 30px auto 0;
        max-width: 203px;
        .president {
          border-radius: 9px;
        }
        .slash {
          width: 186px;
          left: -134px;
          top: -15px;
        }
        .name {
          margin-top: 20px;
          justify-content: center;
          span {
            font-size: 20px;
          }
        }
      }
      p {
        margin-top: 26px;
      }
    }

    .block-certification {
      margin-top: 71px;
      .unit {
        margin-top: 34px;
      }
      picture {
        display: block;
        max-width: 157px;
        margin: auto;
      }
      p {
        margin-top: 24px;
      }
    }

    .block-info {
      margin-top: 85px;
      .base_table {
        margin-top: 8px;
        tr {
          padding-inline: 12px;
        }

        .round_list {
          li {
            padding-left: 14px;
            + li {
              margin-top: 4px;
            }
            &::before {
              width: 10px;
              top: 6px;
            }
          }
        }

        .col_list {
          margin-top: 10px;
          li + li {
            margin-top: 10px;
          }
        }

        p + p {
          margin-top: 3px;
        }

        .note {
          margin-top: 10px;
          font-size: 12px;
        }
      }

      .facility_list {
        margin-top: -20px;
        img {
          width: 100%;
        }
        li {
          + li {
            margin-top: 45px;
          }
        }
        .desc {
          margin-top: 21px;
        }
        table {
          margin-top: 16px;
          tr {
            + tr {
              margin-top: 3px;
            }
          }
        }
        .map {
          margin-top: 13px;
          a {
            margin-inline: auto 0;
            font-size: 13px;
          }
        }
      }
    }

    .map_wrap {
      margin-top: 38px;
      ul {
        height: 150px;
        @media (min-width: 700px) {
          height: 250px;
        }
      }
      iframe {
        margin-top: -40px;
        height: calc(100% + 80px);
        @media (min-width: 400px) {
          margin-top: -132px;
          height: calc(100% + 260px);
        }
      }
    }

    .block-history {
      border-radius: 0 0 25px 25px;
      padding-block: 53px 60px;
      .history_table {
        margin-top: 38px;
        tr {
          padding-left: 20px;
          display: block;
          &::before,
          &::after {
            top: 3px;
          }
          &::before {
            left: -4px;
          }
          &:not(:last-of-type) {
            padding-bottom: 31px;
            &::after {
              left: 0;
            }
          }
        }
        td {
          line-height: 1.5;
          margin-top: 5px;
        }
      }
    }
  }
}
