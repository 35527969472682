.rwd002-container {
  margin-top: map-get($spacers, 6);
  @include media-down(lg) {
    margin-top: map-get($spacers, 3);
  }
}

.is--privacy, .is--sitemap {
  .rwd002-moduletitle {
    display: none;
  }
}