body.is--business.is--article-2 {
  .block-lead {
    margin-top: 62px;
    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 2;
      letter-spacing: .11em;
      @include pc {
        text-align: center;
      }
    }
    .anchor_list {
      margin-top: 75px;
      @include pc {
        grid-template-columns: repeat(5, 1fr);
        @include max(1000px){
          column-gap: 25px;
        }
      }
    }
  }

  .block-content {
    margin-top: 113px;

    .line_ttl {
      font-size: 18px;
      &::before {
        top: 10px;
      }
    }

    .point_list {
      margin-top: 41px;
      @include pc {
        .point_card:first-of-type .text {
          max-width: 410px;
        }
      }
    }

    .content_lead {
      margin-top: 67px;
      .more_info {
        margin-inline: calc(50% - 50vw);
        padding: 78px 50px;
        box-sizing: border-box;
        position: relative;
        z-index: 1;
        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          background-color: #F5F5F5;
          z-index: -1;
        }
        &::before {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 100px;
          overflow: hidden;
        }
        &::after {
          width: 42px;
          height: 22px;
          clip-path: polygon(50% 0, 100% 100%, 0 100%);
          left: 0;
          right: 0;
          top: -21px;
          margin: auto;
        }

        ul {
          max-width: 805px;
          margin-inline: auto;
        }
        li {
          + li {
            margin-top: 30px;
          }
        }
        h5 {
          font-size: 18px;
          letter-spacing: .08em;
        }
        p {
          margin-top: 13px;
        }
        @include pc {
          li {
            padding-left: 180px;
            position: relative;
            min-height: 140px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            img {
              top: 0;
              bottom: 0;
              left: 0;
              margin: auto;
              width: 140px;
              position: absolute;
            }
          }
        }
      }
    }

    .content_list {
      margin-top: 50px;

      @include pc {
        .center_card {
          flex-direction: column-reverse;
          max-width: 720px;
          margin-inline: auto;
          row-gap: 42px;
          padding-top: 102px;
          picture, .desc {
            width: 100%;
          }
          .slash_ttl {
            justify-content: center;
          }
          h5 {
            margin-top: 49px;
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            line-height: 1;
            letter-spacing: .12em;
          }
          p {
            margin-top: 18px;
          }
        }
      }
    }

    .policy_wrap {
      background-color: #F5F5F5;
      margin-top: 40px;
      padding: 53px 40px 37px;
      box-sizing: border-box;
      border-radius: 15px;
      @include pc {
        display: grid;
        grid-template-columns: min(100%, 740px);
        justify-content: center;
      }

      ul {
        margin-top: 21px;
        li {
          font-weight: 700;
          line-height: 2.1;
          position: relative;
          padding-left: 18px;
          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $theme_pink;
            left: 0;
            top: 10px;
          }
          + li {
            margin-top: 18px;
          }
        }
      }
      .text {
        margin-top: 25px;
        font-weight: 700;
      }
      .date {
        margin-top: 16px;
        text-align: right;
        font-size: 13px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: .12em;
      }
    }
  }

  .bros_link_wrap.bottom {
    margin-top: 120px;
  }

  @include sp {
    .block-lead {
      margin-top: 41px;
      p {
        font-size: 15px;
        line-height: 1.7;
        letter-spacing: .08em;
      }
      .anchor_list {
        margin-top: 31px;
      }
    }

    .block-content {
      margin-top: 46px;
      .point_list {
        margin-top: 26px;
        img{
          width: 100%;
        }
      }
  
      .content_lead {
        margin-top: 75px;
        img{
          width: 100%;
        }
        .more_info {
          margin: 26px -27px 0;
          padding: 42px 26px;
          &::before {
            border-radius: 18px;
          }
          &::after {
            width: 34px;
            height: 14px;
            top: -12px;
          }
  
          li {
            + li {
              margin-top: 33px;
              padding-top: 37px;
              border-top: 1px solid #DADADA;
            }
          }
          .ttl_wrap {
            display: grid;
            grid-template-columns: 65px 1fr;
            column-gap: 15px;
            align-items: center;
          }
          h5 {
            font-size: 18px;
            letter-spacing: .08em;
            &::before {
              display: none;
            }
          }
          p {
            margin-top: 7px;
            font-size: 14px;
            line-height: 1.6;
          }
        }
      }

      .content_list {
        margin-top: 75px;
        img{
          width: 100%;
        }
      }

      .center_card {
        h5 {
          margin-top: 17px;
          font-size: 18px;
          line-height: 1.6;
          letter-spacing: .12em;
        }
        p {
          margin-top: 13px;
        }
      }

      .policy_wrap {
        margin: 40px -12px 0;
        padding: 43px 34px 33px;
        border-radius: 11px;
  
        ul {
          margin-top: 21px;
          li {
            line-height: 1.9;
            padding-left: 16px;
            &::before {
              width: 10px;
              height: 10px;
              top: 10px;
            }
            + li {
              margin-top: 21px;
            }
          }
        }
        .text {
          margin-top: 15px;
        }
        .date {
          margin-top: 15px;
          font-size: 13px;
          letter-spacing: .12em;
        }
      }
    }
  
    .bros_link_wrap.bottom {
      margin-top: 71px;
    }
  }
}