@mixin c-module-title() {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.75em;
  font-weight: normal;
  letter-spacing: 0.22em;
  text-indent: 0.22em;
  font-size: $h2-font-size;
  @include media-down(lg) {
    font-size: 1.875rem;
  }
  @include media-down(md) {
    font-size: $h4-font-size;
  }

  span {
    font-family: "Roboto", $font-family-sans-serif;
    font-weight: $font-weight-normal;
  }

  &:after{
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    width: 82px;
    height: 1px;
    margin: auto;
    background: $primary;
  }
}

.sec_ttl {
  text-align: center;
  strong, span {
    display: block;
  }
  strong {
    font-size: 26px;
    line-height: 1.5;
    letter-spacing: .08em;
  }
  span {
    @include Viga;
    font-size: 11px;
    letter-spacing: .08em;
    color: $theme-pink;
    margin-top: 5px;
    line-height: 1;
  }
  
  @include sp {
    strong {
      font-size: 23px;
    }
    span {
      letter-spacing: .12em;
      margin-top: 5px;
    }
  }
}

.slash_ttl {
  display: flex;
  column-gap: 15px;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: .15em;
  &::before {
    content: '';
    display: block;
    position: relative;
    width: 34px;
    aspect-ratio: 17 / 13;
    overflow: hidden;
    background: url(/assets/img/common/ttl_slash.svg) no-repeat 0 / contain;
  }

  &.en {
    @include Viga;
    font-size: 14px;
    line-height: 1;
    letter-spacing: .12em;
    column-gap: 7px;
    align-items: center;
    &::before {
      width: 18px;
    }
  }
  
  @include sp {
    column-gap: 7px;
    font-size: 22px;
    letter-spacing: .08em;
    &::before {
      width: 25px;
    }
  
    &.en {
      column-gap: 5px;
      &::before {
        width: 17px;
      }
    }

  }
}

.line_ttl {
  display: flex;
  column-gap: 7px;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: .18em;
  &::before {
    content: '';
    display: block;
    position: relative;
    width: 18px;
    height: 1px;
    background-color: $theme-pink;
    top: 14px;
  }

  @include sp {
    column-gap: 7px;
    font-size: 18px;
    &::before {
      top: 11px;
    }
  }

  .editor-template & {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.3;
    @include sp {
      font-size: 18px;
    }
  }
}
