body.is--recruit {
  .rwd002 {
    &-detail {
      &__head {
        padding-inline: 50px;
        box-sizing: border-box;
        margin-block: 76px 45px;
      }
      &__title {
        border: none;
        padding-bottom: 16px;
        position: relative;
        text-align: center;
        font-size: 25px;
        letter-spacing: .08em;
        margin-bottom: 0;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 39px;
          height: 1px;
          background-color: $theme-pink;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
      &__desc {
        display: none;
      }

      &__image {
        max-width: 1060px;
        padding-inline: 50px;
        margin-inline: auto;
        &-inner {
          border-radius: 15px;
          overflow: hidden;
        }
      }
    }
  }

  .editor-template {
    max-width: 900px;
    padding-inline: 50px;
    margin-inline: auto;
  }

  .detail_btn_wrap {
    margin-top: 40px;
    padding-inline: 27px;
    box-sizing: border-box;

    .link_btn {
      margin-inline: auto;
      column-gap: 10px;
      height: 85px;
      width: min(100%, 485px);
      border-radius: 43px;
      justify-content: center;
      .icon {
        width: 26px;
      }
      span {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: .18em;
      }
      .arrow {
        right: 28px;
      }
    }
  }

  @include sp {
    .rwd002 {
      &-detail {
        &__head {
          padding-inline: 50px;
          margin-block: 12px 25px;
        }
        &__title {
          padding-bottom: 12px;
          font-size: 20px;
        }
  
        &__image {
          padding-inline: 27px;
          margin-bottom: 32px;
          &-inner {
            border-radius: 5px;
          }
        }
      }
    }
  
    .editor-template {
      padding-inline: 27px;
    }
  
    .detail_btn_wrap {
      .link_btn {
        column-gap: 10px;
        height: 62px;
        width: min(100%, 313px);
        border-radius: 31px;
        .icon {
          width: 22px;
        }
        span {
          font-size: 15px;
          letter-spacing: .12em;
        }
        .arrow {
          right: 19px;
          width: 12px;
        }
      }
    }
  }
}