body.is--recruit.is--article-2 {
  #allbox {
    overflow: unset;
  }
  h3, p {
    word-break: normal;
    line-break: normal;
  }
  
  .lead_wrap {
    margin-top: 32px;
    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 2.1;
      letter-spacing: .18em;
      @include pc {
        text-align: center;
      }
    }
  }

  .block-voice {
    margin-top: 10px;
    .card {
      picture {
        display: block;
        border-radius: 20px;
        overflow: hidden;
      }
      .desc {
        h3 {
          font-size: 29px;
          font-weight: 700;
          line-height: 1.5;
          letter-spacing: .11em;
          margin-top: 23px;
        }
        dl {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 10px 30px;
          padding: 18px 46px;
          box-sizing: border-box;
          border-radius: 24px;
          background-color: #F5F5F5;
          margin-top: 23px;
        }
        dt {
          font-size: 14px;
          font-weight: 700;
          line-height: 1;
        }
        dd {
          font-size: 12px;
          line-height: 1;
        }
        .text {
          margin-top: 25px;
        }
      }
      
      @include pc {
        padding-block: 104px;
        border-radius: 100px;
        .container {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
        picture {
          width: 323px;
          position: sticky;
          top: 20px;
        }
        .desc {
          width: calc(100% - 370px);
        }

        &:nth-of-type(2n + 1) .container{
          flex-direction: row-reverse;
        }
        &:nth-of-type(2n){
          background-color: #F5F5F5;
          dl {
            background-color: #fff;
          }
        }
        &:last-of-type {
          border-radius: 100px 100px 0 0;
        }
      }
    }
  }

  @include sp {
    .lead_wrap {
      margin-top: 26px;
      p {
        font-size: 15px;
        line-height: 1.8;
        letter-spacing: .08em;
      }
    }

    .block-voice {
      margin-top: 33px;
      .card {
        + .card {
          margin-top: 70px;
        }

        picture {
          display: block;
          border-radius: 20px;
          overflow: hidden;
          img{
            width: 100%;
          }
        }
        .desc {
          margin-top: 37px;
          .slash_ttl {
            font-size: 12px;
            &::before {
              width: 14px;
            }
          }
          h3 {
            font-size: 20px;
            line-height: 1.5;
            margin-top: 16px;
          }
          dl {
            row-gap: 11px;
            padding: 18px 28px;
            border-radius: 10px;
            margin-top: 18px;
          }
          dd {
            line-height: 1.3;
          }
          .text {
            margin-top: 19px;
          }
        }
      }
    }
  }
}