@function svg-right-angle($theme: $primary) {
  @return svgUrlFunc(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
  );
}

@function svg-bottom-angle($theme: $body-color) {
  @return svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>');
}

.btn-icon-right-angle {
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: calc(50% - 0.3em);
    right: 1em;
    width: 1em;
    height: 0.6em;
    background: center / contain no-repeat;
    background-image: svg-right-angle();
  }
  &:hover:after,&:focus:after{
    background-image: svg-right-angle($white);
  }
}

.btn-icon-right-angle-white{
  @extend .btn-icon-right-angle;
  &:after{
    background-image: svg-right-angle($white);
  }
}

.btn-icon-bottom-angle {
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: calc(50% - 0.3em);
    right: 0;
    width: 2em;
    height: 0.6em;
    transition: all .5s ease-in-out;
    background: center / contain no-repeat;
    background-image: svg-bottom-angle();
    transform: rotateX(180deg);
  }
  &.collapsed:after{
    transform: rotateX(0deg);
  }
}

.link_btn {
  display: flex;
  align-items: center;
  position: relative;
  width: 212px;
  height: 52px;
  border-radius: 26px;
  overflow: hidden;
  padding-inline: 22px;
  box-sizing: border-box;
  z-index: 1;
  span {
    @include Viga;
    font-size: 12px;
    letter-spacing: .12em;
    color: #fff;
  }
  .arrow {
    width: 14px;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
  }
  &::before {
    z-index: -2;
    background: linear-gradient(90deg, #E61211, #E4007F);
  }
  &::after {
    background: $theme-grd;
    z-index: -1;
    transition: $transition-base;
  }

  @include sp {
    margin-inline: auto;
  }
  
  &:hover {
    .arrow {
      transform: translateX(5px);
    }
    &::after {
      opacity: 0;
    }
  }
}

.capsule_arrow {
  display: grid;
  place-content: center;
  grid-template-columns: 14px;
  width: 59px;
  height: 22px;
  border-radius: 11px;
  background: $theme-grd;
  .arrow {
    width: 14px;
  }
}
.arrow_trigger:hover .arrow {
  transform: translateX(3px);
}