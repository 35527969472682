@mixin hoverOpacity($op:0.8,$dur:0.2s){
  transition: opacity $dur ease-in-out;
  &:hover,
  &:focus{
    opacity: $op;
  }
}


// Bootstrapのメディアクエリ用ミックスインをリネーム
// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

$font-color: #373737;
$theme-pink: #FF0069;
$theme-grd: linear-gradient(90deg, #FF0053, #FF5080);

$pc: 900px;
$sp: 899px;

@mixin pc {
  @media (min-width: $pc){
    @content;
  }
}
@mixin sp {
  @media (max-width: $sp){
    @content;
  }
}

@mixin max($width){
  @media (max-width: $width){
    @content;
  }
}

@mixin min($width){
  @media (min-width: $width){
    @content;
  }
}

// 400,500,700
@mixin Roboto($weight){
  font-family: 'Roboto';
  font-weight: $weight;
}

// 400
@mixin Viga{
  font-family: 'Viga';
  font-weight: 400;
}